<template>
  <invoice-single-details />
</template>

<script>
import InvoiceSingleDetails from '@/components/subscriptions/InvoiceSingleDetails.vue'

export default {
  components: {
    InvoiceSingleDetails
  },
}
</script>
