<template>
  <router-link tag="a" :to="routeTo">
    <div class="row">
      <div class="col-6 col-lg-4 col-xl-3">
        <p>
          <span class="data-label d-xl-none">Name</span>
          {{ subscriber.name }}
        </p>
      </div>

      <div class="col-6 col-lg-4 col-xl-3">
        <p>
          <span class="data-label d-xl-none">Type</span>
          {{ subscriber.type }}
        </p>
      </div>

      <div class="col-6 col-lg-4 col-xl-2">
        <p>
          <span class="data-label d-xl-none">Plan</span>
          {{ subscriber.package_name }}
        </p>
      </div>

      <div class="col-6 col-lg-4 col-xl-2">
        <p>
          <span class="data-label d-xl-none">Price</span>
          {{ price }}
        </p>
      </div>

      <div class="col-6 col-lg-4 col-xl-2">
        <p>
          <span class="data-label d-xl-none">Status</span>
          <span v-if="isActiveStatus" class="status status-success">Active</span>
          <span v-else class="status status-danger">Inactive</span>
        </p>
      </div>
    </div><!-- /.row -->
  </router-link>
</template>

<script>
export default {
  props: {
    subscriber: {
      required: true,
      type: Object
    }
  },

  computed: {
    price () {
      if (!this.subscriber.price) {
        return '-'
      }

      const priceFormatted = (this.subscriber.price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      return `${priceFormatted} /${this.subscriber.billing_frequency}`
    },

    isActiveStatus () {
      return ['active', 'trialing'].includes(this.subscriber.status)
    },

    routeTo () {
      if (this.subscriber.type === 'Training Provider') {
        return {
          name: 'admin.providers.single.subscription',
          params: {
            providerId: this.subscriber.id
          }
        }
      }

      return {
        name: 'users.single.subscription',
        params: {
          userId: this.subscriber.id
        }
      }
    }
  },
}
</script>
