<template>
  <fragment>
    <app-header title="Learning Plan" />

    <div class="container">

      <learning-plan-form
        :disabled="false"
        new-learning-plan
      />
    </div>
  </fragment>
</template>

<script>
import LearningPlanForm from '@/components/learning-plans/LearningPlanForm.vue'

export default {
  components: {
    LearningPlanForm
  },
}

</script>
