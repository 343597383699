<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col d-flex align-items-center">
          <h2>Med CPD Premium</h2>
        </div>
        <div class="col-auto">
          <span
            v-if="activeSubscription.is_cancelled"
            class="badge badge-subscription bg-danger"
          >
            Cancelled
          </span>
          <span v-else class="badge badge-subscription bg-success">Active</span>
        </div>
      </div>

      <p v-if="!enterprisePlan" class="h4 text-primary mb-4">{{ amount }} /{{ activeSubscription.billing_frequency }}</p>
      <p v-else class="h4 text-primary mb-4">Price on Application</p>

      <div v-if="cancelledDate" class="alert alert-danger" role="alert">
        <div class="alert-content">
          <div class="row flex-grow-1">
            <div class="col-sm d-flex align-items-center mb-2 mb-sm-0">
              <div>
                <h2 class="alert-heading d-inline-block">Subscription Cancelled</h2>
                <p class="small">
                  Your subscription has been canceled, please
                  resubscribe before {{ cancelledDate }} to prevent loosing
                  access to your subscription features.
                </p>
              </div>
            </div>
            <div class="col-sm-auto d-flex align-items-center">
              <c-button
                @click="resubscribe"
                type="button"
                class="btn btn-sm bg-white text-danger"
              >
                <i class="far fa-redo me-1"></i> Resubscribe
              </c-button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!enterprisePlan" class="row gx-2 mb-2 text-muted">
        <div class="col-12 col-sm-4 col-lg-3">
          <strong>Billing frequency:</strong>
        </div>
        <div class="col">
          {{ activeSubscription.billing_frequency_label }}
        </div>
        <div class="col-auto">
          <router-link v-if="$isA('user')" :to="{ name: 'my.subscription.change-plan' }" class="text-info small">
            <i class="far fa-pencil"></i>
            Update
          </router-link>

          <router-link v-if="$isA('admin') && !providerSubscription" :to="{ name: 'users.single.subscription.subscribe' }" class="text-info small">
            <i class="far fa-pencil"></i>
            Update
          </router-link>

          <router-link v-if="$isA('admin') && providerSubscription" :to="{ name: 'admin.providers.single.subscription.subscribe' }" class="text-info small">
            <i class="far fa-pencil"></i>
            Update
          </router-link>
        </div>
      </div>

      <div class="row gx-2 mb-2 text-muted" v-if="!activeSubscription.is_cancelled && !enterprisePlan">
        <div class="col-12 col-sm-4 col-lg-3">
          <strong v-if="activeSubscription.is_trial">Trial ends at:</strong>
          <strong v-else>Next bill:</strong>
        </div>
        <div class="col">
          {{ nextBill }}
        </div>
      </div>

      <div class="row gx-2 mb-2 text-muted">
        <div class="col-12 col-sm-4 col-lg-3">
            <strong>Payment method:</strong>
        </div>

        <div class="col" v-if="activeSubscription.payment_method">

          <div class="row">
            <div class="col-auto">
              <img
                :src="paymentMethodLogo"
                alt="payment logo"
                width="64"
              />
            </div>
            <div class="col-auto">&bull;&bull;&bull;&bull; {{ activeSubscription.payment_method.card_last_four }}</div>
            <div class="col-auto">Expires: {{ paymentMethodExpires }}</div>
          </div>
        </div>

        <div class="col" v-else>
          <div class="row">
            <div class="col-auto">-</div>
          </div>
        </div>

        <div v-if="$isNotA('admin')" class="col-auto">
          <router-link :to="updateCardDetailsLink" class="text-info small">
            <i class="far fa-pencil"></i>
            Update
          </router-link>
        </div>
      </div>

      <div v-if="providerSubscription" class="row gx-2 mb-2 text-muted">
        <div class="col-12 col-sm-4 col-lg-3">
          <strong>Billing email:</strong>
        </div>
        <div class="col">
          {{ billingEmails }}
        </div>

        <div class="col-auto">
          <router-link :to="providerBillingEmailLink" class="text-info small">
            <i class="far fa-pencil"></i>
            Update
          </router-link>
        </div>
      </div>

      <button
        v-if="!cancelledDate"
        type="button"
        class="btn btn-md btn-danger mt-4 me-2"
        v-c-modal:cancelSubscriptionModal
      >
        <i class="far fa-times"></i>
        Cancel Subscription
      </button>

      <router-link
        v-if="providerSubscription && providerChangePlanLink"
        type="button"
        class="btn btn-md btn-info mt-4"
        :to="providerChangePlanLink"
      >
        <i class="far fa-random"></i>
        Change Plan
      </router-link>
    </div>

    <cancel-subscription-modal ref="cancelSubscriptionModal" />
    <resubscribe-modal ref="resubscribeModal" />
  </div><!-- /.card -->
</template>

<script>
import { get, call } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'
import { http } from '@/utils'
import CancelSubscriptionModal from '@/components/subscriptions/CancelSubscriptionModal.vue'
import ResubscribeModal from '@/components/subscriptions/ResubscribeModal.vue'
import dayjs from 'dayjs'
import visaLogo from '@/assets/img/payment-logo/visa.svg'
import mastercardLogo from '@/assets/img/payment-logo/mastercard.svg'

export default {
  components: {
    CancelSubscriptionModal,
    ResubscribeModal,
  },

  computed: {
    activeSubscription: get('activeSubscriptions/activeSubscription'),
    provider: get('providers/provider'),

    paymentMethodLogo () {
      if (this.activeSubscription.payment_method?.card_brand === 'visa') {
        return visaLogo
      }

      if (this.activeSubscription.payment_method?.card_brand === 'mastercard') {
        return mastercardLogo
      }

      return null
    },

    paymentMethodExpires () {
      return `${this.activeSubscription?.payment_method?.card_expiry_month}/${this.activeSubscription?.payment_method?.card_expiry_year}`
    },

    nextBill () {
      return dayjs(this.activeSubscription.current_period_end).format('DD/MM/YYYY')
    },

    cancelledDate () {
      if (!this.activeSubscription?.ends_at) {
        return null
      }

      return dayjs(this.activeSubscription?.ends_at).format('DD/MM/YYYY')
    },

    amount () {
      return (this.activeSubscription?.price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },

    billingEmails () {
      return this.activeSubscription?.billing_emails?.join(', ')
    },

    updateCardDetailsLink () {
      if (this.$isA('provider')) {
        return {
          name: 'providers.single.subscription.payment-method',
        }
      }

      return {
        name: 'my.subscription.update-payment-method',
      }
    },

    providerChangePlanLink () {
      if (!this.provider.id) {
        return null
      }

      if (this.$isA('admin')) {
        return {
          name: 'admin.providers.single.subscription.subscribe'
        }
      }

      return {
        name: 'providers.single.subscription.change-plan'
      }
    },

    providerBillingEmailLink () {
      return {
        name: `${this.$isA('admin') ? 'admin.' : ''}providers.single.subscription.billing-email`
      }
    },

    enterprisePlan () {
      return this.activeSubscription?.price === 0 && this.activeSubscription?.custom_price > 0
    },

    providerSubscription () {
      return this.$isA('provider') || (this.provider.id && this.$route.name.includes('providers'))
    },
  },

  methods: {
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),
    async resubscribe () {
      try {
        let params = {}

        if (this.$isA('admin')) {
          params = {
            providerId: this.$route.params.providerId,
            userId: this.$route.params.userId,
          }
        }

        await http.post('/subscription/resubscribe', null, { params })
        await this.$nextTick()

        await this.getActiveSubscription({
          type: this.provider?.id ? 'providers' : 'users',
          id: this.$route.params.userId ?? this.$route.params.providerId,
        })
        this.$refs.resubscribeModal.open()
      } catch (error) {
        toast(error.response.data.message, 'danger')
      }
    },
  }
}
</script>
