<template>
  <li>
    <c-radio
      type="radio"
      name="radio-plan"
      :value="pricing.id"
      v-model="selected"
    >
      {{ pricing.billing_frequency_label }}
      <span v-if="!isMonthly && !showCurrent">{{ saveCost }}</span>
      <span v-if="showCurrent && isCurrent"> (current)</span>
      <span class="price">{{ price }} /{{ pricing.billing_frequency }}</span>
    </c-radio>
  </li>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  props: {
    pricing: {
      type: Object,
      required: true
    },
    value: {
      type: Number,
      required: false
    },
    showCurrent: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    subscriptionPackage: get('packages/packages'),
    activeSubscription: get('activeSubscriptions/activeSubscription'),

    isMonthly () {
      return this.pricing.billing_frequency === 'month'
    },

    price () {
      return this.formatPrice(this.pricing.price)
    },

    saveCost () {
      const monthlyPrice = this.subscriptionPackage.pricings.find((pricing) => pricing.billing_frequency === 'month')

      if (!monthlyPrice) {
        return null
      }

      const amountSaved = (monthlyPrice.price * 12) - this.pricing.price
      if (amountSaved <= 0) {
        return null
      }

      return ` (Save ${this.formatPrice(amountSaved)})`
    },

    isCurrent () {
      return this.pricing.id === this.activeSubscription.package_pricing_id
    },

    selected: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    formatPrice (price) {
      return (price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    }
  },
}
</script>
