<template>
  <div class="form-check abc-checkbox abc-checkbox-success">
    <input
      class="form-check-input"
      :id="`ability-checkbox-${ability.name}`"
      type="checkbox"
      v-model="selected"
      v-bind="{ disabled }"
    />
    <label class="form-check-label" :for="`ability-checkbox-${ability.name}`">
      {{ ability.title }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    abilities: Array,
    ability: {
      required: true,
      type: Object
    },
    role: {
      required: false,
      type: Object
    },
  },

  model: {
    prop: 'abilities'
  },

  computed: {
    selected: {
      get () {
        return this.abilities.find(({ name }) => name === this.ability.name) !== undefined
      },

      set (value) {
        this.updateAbilities(value)
      }
    },

    disabled () {
      return this.role && this.role.abilities.find(({ name }) => name === this.ability.name) !== undefined
    }
  },

  methods: {
    updateAbilities (selected) {
      const abilities = selected ? [this.ability].concat(this.abilities) : this.abilities.filter(({ name }) => name !== this.ability.name)
      this.$emit('input', abilities)
    }
  }
}
</script>
