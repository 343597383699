<template>
  <fragment>
    <app-header title="Users">
      <template #actions>
        <router-link
          class="btn btn-primary btn-md"
          :to="{ name: 'users.add' }"
        >
          <i class="far fa-plus"></i> Med CPD Admin
        </router-link>
      </template>
    </app-header>

    <div class="container">
      <users-list-filters v-model="params" />

      <c-data-list :items="users" @load="load">
        <template #columns>
          <div class="col-xl-4">
            <p>Name</p>
          </div>
          <div class="col-xl-3">
            <p>Email Address</p>
          </div>
          <div class="col-xl-3">
            <p>Account Type</p>
          </div>
          <div class="col-xl-2">
            <p>Status</p>
          </div>
        </template>

        <template #default="{ item }">
          <users-list-item :user="item" />
        </template>
      </c-data-list>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/users'
import { compareQueryParams } from '@codium/codium-vue'
import UsersListItem from './UsersListItem'
import UsersListFilters from './UsersListFilters'

export default {
  components: {
    UsersListItem,
    UsersListFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getUsers({ params, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetUsers()
  },

  computed: {
    ...helperComputed
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getUsers({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
