<template>
  <fragment>
    <subscription-details-card />

    <component :is="invoiceListComponent" />
  </fragment>
</template>

<script>
import SubscriptionDetailsCard from '@/components/subscriptions/SubscriptionDetailsCard.vue'
import InvoiceList from '@/components/subscriptions/InvoiceList.vue'
import InvoiceListAdmin from '@/components/users/InvoiceList.vue'

export default {
  components: {
    SubscriptionDetailsCard,
    InvoiceList,
    InvoiceListAdmin,
  },

  computed: {
    invoiceListComponent () {
      if (this.$isA('admin')) {
        return InvoiceListAdmin
      }

      return InvoiceList
    }
  }
}
</script>
