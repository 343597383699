<template>
  <fragment>
    <app-header title="Subscribers" />

    <div class="container">
      <subscribers-list-filters v-model="params" />

      <c-data-list :items="subscribers" @load="load" keyBy="list_key">
        <template #columns>
          <div class="col-xl-3">
            <p>Name</p>
          </div>
          <div class="col-xl-3">
            <p>Type</p>
          </div>
          <div class="col-xl-2">
            <p>Plan</p>
          </div>
          <div class="col-xl-2">
            <p>Price</p>
          </div>
          <div class="col-xl-2">
            <p>Status</p>
          </div>
        </template>

        <template #default="{ item }">
          <subscribers-list-item :subscriber="item" />
        </template>
      </c-data-list>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/subscribers'
import { compareQueryParams } from '@codium/codium-vue'
import SubscribersListFilters from './SubscribersListFilters'
import SubscribersListItem from './SubscribersListItem.vue'

export default {
  components: {
    SubscribersListFilters,
    SubscribersListItem,
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getSubscribers({ params, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetSubscribers()
  },

  computed: {
    ...helperComputed
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getSubscribers({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
