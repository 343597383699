<template>
  <fragment>
    <slot name="nav" />

    <div class='col'>
      <div class="card">
        <div class="card-body">
          <h2>Annual CPD Summary</h2>
          <p>
            Please find a summary of your CPD hours which have been logged below.          </p>
            <h3 class="mt-4">Professional Development Hours</h3>
          <div class="row">
              <div class="col-md-6 col-xl-4 mb-3">
                <p class="form-label">EA - Educational Activity</p>
                {{ summary.educational_activity_count }}
              </div>
              <div class="col-md-6 col-xl-4 mb-3">
                <p class="form-label">RP - Reviewing Performance</p>
                {{ summary.reviewing_performance_count }}
              </div>
              <div class="col-md-6 col-xl-4 mb-3">
                <p class="form-label">MO - Measuring Outcomes</p>
                {{ summary.measuring_outcomes_count }}
              </div>
          </div>
          <h3 class="mt-4">Total Hours</h3>
          <div class="row">
            <div class="col-md-6 col-xl-4 mb-3">
                <p class="form-label">PDP Hours</p>
                {{ summary.total_hours }}
              </div>
              <div class="col-md-6 col-xl-8 mb-3">
                <div class="row">
                  <div class="col">
                    <p class="form-label">Export PDP</p>
                  </div> <div class="col-auto">
                  <button :disabled="loading" @click='downloadPdf()' target="_blank" class="btn btn-primary"><i class="far fa-file-pdf me-2"></i> Download</button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <p class="text-muted">This function was added to the Med CPD platform to help you easily build, maintain and organise your PDP, activities and reflections. Your feedback and review of this structure and content would be much appreciated as Med CPD is dedicated to improving, maintaining and solving the needs of our community of health professionals.</p>
            <p class="text-muted">If you have any feedback on this guide and content please send it through to support@medcpd.com we appreciate and look forward to hearing from you!</p>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>
<script>
import { http } from '@/utils'
export default {

  data () {
    return {
      params: null,
      summary: [],
      loading: false,
    }
  },

  async created () {
    try {
      const response = await http.get(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/summary`, { throwForbiddens: true })
      this.summary = response.data
    } catch {

    }
  },
  methods: {
    async downloadPdf () {
      this.loading = true
      const { data } = await http.get(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/summary/pdf`, { responseType: 'blob' })

      this.loading = false
      const anchor = document.createElement('a')

      anchor.href = window.URL.createObjectURL(new Blob([data]))
      anchor.setAttribute('download', 'professional-development-plan.pdf')

      document.body.appendChild(anchor)

      anchor.click()
      anchor.remove()
    }
  }
}
</script>
