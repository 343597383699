<template>
  <fragment>
    <slot name="nav" />

    <div class='col'>
      <div class="card">
        <div class="card-body">
          <h2>Identified Goals</h2>
          <p>Add each of the goals you have identified this year.</p>
          <div id="accordiongoals" class="accordion accordion-flush mb-3 accordion-card">
            <div v-for="goal in goals" class="accordian-item" v-bind:key="goal.id">
              <h2 id="flush-1" class="accordion-header">
                <button type="button" data-bs-toggle="collapse" :data-bs-target="'#accordion-collapse-' + goal.id" :aria-controls="'accordion-collapse-' + goal.id"  class="accordion-button collapsed" @click="updateSelectedGoal(goal.id)">
                    {{ goal.name }}
                </button>
              </h2>
              <div :id="'accordion-collapse-' + goal.id" aria-labelledby="flush-1" data-bs-parent="#accordiongoals" class="accordion-collapse collapse" style="">
                <div class="accordion-body"><div class="mb-3"><label class="form-label">Goal Name</label>
                 <input type="text" class="form-control" v-model="goal.name">
                </div>
                <div class="mb-3">
                  <label class="form-label">What is the specific description of the goal outcome?</label>
                  <input type="text" class="form-control" v-model="goal.description">
                </div>
                <div class="mb-3">
                  <label class="form-label">What will let you know that this has been achieved?</label>
                  <input type="text" class="form-control" v-model="goal.outcome">
                </div>
                <div class="mb-3">
                  <label class="form-label">How do you plan on achieving this goal?</label>
                  <input type="text" class="form-control" v-model="goal.plan">
                </div>
                <div class="mb-3">
                  <label class="form-label">What is your ideal time frame for this goal?</label>
                  <input type="text" class="form-control" v-model="goal.timeframe"></div>
                  <div class="mb-3">
                    <label class="form-label">Do you believe that this is an achievable goal in the time frame you have set?</label>
                    <input type="text" class="form-control" v-model="goal.is_achievable">
                  </div>
                  <h4>List all the activities that you have completed for this Goal.</h4>
                  <ul class="list-data mb-2">
                    <li v-for="activity in goal.goal_activities" v-bind:key="activity.id">
                      <a href="#" data-bs-toggle="modal" data-bs-target="#modal-drawer-activity" @click="openActivityModal(activity)">
                        <div class="row">
                          <div class="col">
                              {{ activity.name }}
                          </div>
                          <div class="col-auto">
                            <i class="far fa-pencil text-info"></i>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <div class="mb-3 text-end">
                    <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target="#modal-drawer-activity" @click="resetActivity"><i class="far fa-plus" ></i> Activity</button>
                  </div>
                  <button type="button" class="btn btn-sm btn-outline-danger" @click="confirmDeleteGoal"><i class="far fa-trash-alt"></i> Delete Goal</button>
                  <button type="button" class="btn btn-sm btn-outline-info" @click="duplicateGoal"><i class="far fa-copy"></i> Duplicate Goal</button>
                  </div>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-primary" @click="createGoal">
                <i class="far fa-plus">

                </i> Goal</button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-primary" @click="save">
                  Save</button>
                </div>
              </div>
        </div>
      </div>
    </div>
    <activity-modal  @activity-changed="fetchGoals" :activity="selectedActivity" :goalId="selectedGoal"/>
  </fragment>
</template>
<script>
import { http } from '@/utils'
import ActivityModal from '@/components/professional-development-plans/ActivityModal.vue'
import { toast } from '@chameleon/chameleon-vue'
import swal from 'sweetalert'

export default {

  components: {
    ActivityModal
  },

  data () {
    return {
      params: null,
      goals: [],
      selectedGoal: '',
      selectedActivity: {
        name: '',
        date: '',
        mode: '',
        description: '',
        outcomes: '',
        outcomes_met: '',
        relevance: '',
        achieved: '',
        impact: '',
        contribution: '',
        educational_activity: null,
        reviewing_performance: null,
        measuring_outcomes: null,
        hours_spent: null
      },
    }
  },
  async created () {
    this.fetchGoals()
  },
  methods: {
    async fetchGoals () {
      try {
        const response = await http.get(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals`, { throwForbiddens: true })
        this.goals = response.data.data
      } catch {
      }
    },
    updateSelectedGoal (id) {
      this.selectedGoal = id
    },
    openActivityModal (activity) {
      this.selectedActivity = activity
    },
    async save () {
      await http.put(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals`, {
        goals: this.goals,
        plan_id: this.$route.params.professionalDevelopmentPlanId
      })
      toast('Goals have been saved.', 'success')
    },
    async createGoal () {
      const response = await http.post(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals`, {
        development_plan_id: this.$route.params.professionalDevelopmentPlanId
      })
      this.goals.push(response.data)
      toast('New goal has been created.', 'success')
    },
    async duplicateGoal () {
      let goal = this.goals.find(goal => goal.id === this.selectedGoal)
      goal = { ...goal, duplicate: true }
      const response = await http.post(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals`, goal)
      this.goals.push(response.data)
    },
    resetActivity () {
      this.selectedActivity = {
        name: '',
        date: '',
        mode: '',
        description: '',
        outcomes: '',
        outcomes_met: '',
        relevance: '',
        achieved: '',
        impact: '',
        contribution: '',
        educational_activity: 0,
        reviewing_performance: 0,
        measuring_outcomes: 0,
        hours_spent: 0
      }
    },
    async confirmDeleteGoal () {
      if (
        !(await swal({
          title: 'Delete Goal',
          text: 'Are you sure you want to delete this goal?',
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: {
              text: 'Yes',
              value: true,
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
        }))
      ) {
        return
      }

      await http.delete(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals/${this.selectedGoal}`)
      this.fetchGoals()
      swal.close()

      toast('Goal has been deleted.', 'info')
    },
  }
}
</script>
