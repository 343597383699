<template>
  <fragment>
    <slot name="tabBar" />

    <div class="container container-max-md mt-4">
      <div class="card">
        <div class="card-body card-body-lg">

          <h2 class="text-primary mb-5">Med CPD Premium</h2>

          <h2>Select Plan</h2>

          <ul class="list-data list-data-plan-select no-hover no-shadow">
            <subscription-user-tier-item
              v-for="pricing in subscriptionPlan.pricings"
              :key="pricing.id"
              :pricing="pricing"
              :show-current="false"
              v-model="selected"
            />
          </ul>

          <h2>Payment Details</h2>

          <div class="row">
            <div class="col-12 mb-3">
              <div class="form-check">
                <c-checkbox
                  v-model="useCustomPrice"
                  class="form-check-inline ps-0"
                  id="form-check-custom-price"
                >
                  Custom Price
                </c-checkbox>
              </div>
            </div>

            <div class="col-sm-6 mb-3">
              <label class="form-label">Price</label>
              <div class="input-group">
                <span class="input-group-text">$</span>

                <c-number
                  v-model="customPrice"
                  class="form-control text-end"
                  placeholder="0.00"
                  :decimals="true"
                />
              </div>
            </div>
          </div>

          <template v-if="activeSubscription.payment_method">
            <h2 class="mt-4">Current Payment Method</h2>

            <div class="row gx-2 mb-4 text-muted">
              <div class="col">

                <div class="row">
                  <div class="col-auto">
                    <img
                      :src="paymentMethodLogo"
                      alt="payment logo"
                      width="64"
                    />
                  </div>
                  <div class="col-auto">&bull;&bull;&bull;&bull; {{ activeSubscription.payment_method.card_last_four }}</div>
                  <div class="col-auto">Expires: {{ paymentMethodExpires }}</div>
                </div>
              </div>
            </div>

            <p>
              <small class="text-muted font-italic">
                Prices shown are in Australian Dollars (AUD) and inclusive of GST.
              </small>
            </p>
          </template>

          <div class="row">
            <div class="col d-flex align-items-center">
            </div>

            <div class="col-auto">
              <c-button
                class="btn btn-md btn-primary"
                @click="save"
              >
                Next
              </c-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import SubscriptionUserTierItem from '@/components/subscriptions/SubscriptionUserTierItem.vue'
import visaLogo from '@/assets/img/payment-logo/visa.svg'
import mastercardLogo from '@/assets/img/payment-logo/mastercard.svg'
import { http } from '@/utils'

export default {
  components: {
    SubscriptionUserTierItem,
  },

  data () {
    return {
      selected: null,
      customPrice: null,
      useCustomPrice: false,
    }
  },

  async created () {
    await this.getPackages({ role: 'user' })

    await this.getActiveSubscription({
      type: 'users',
      id: this.$route.params.userId,
    }).catch(() => {})

    if (this.activeSubscription) {
      this.selected = this.activeSubscription.package_pricing_id
      this.customPrice = this.activeSubscription.custom_price ? this.activeSubscription.custom_price / 100 : null
      this.useCustomPrice = !!this.activeSubscription.custom_price
    }
  },

  computed: {
    subscriptionPlan: get('packages/packages'),
    activeSubscription: get('activeSubscriptions/activeSubscription'),

    paymentMethodLogo () {
      if (this.activeSubscription.payment_method?.card_brand === 'visa') {
        return visaLogo
      }

      if (this.activeSubscription.payment_method?.card_brand === 'mastercard') {
        return mastercardLogo
      }

      return null
    },

    paymentMethodExpires () {
      return `${this.activeSubscription?.payment_method?.card_expiry_month}/${this.activeSubscription?.payment_method?.card_expiry_year}`
    },
  },

  methods: {
    getPackages: call('packages/getPackages'),
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),

    async save () {
      const userId = this.$route.params.userId

      await http.post(`/users/${userId}/subscribe`, {
        package_pricing_id: this.selected,
        custom_price: this.customPrice,
        use_custom_price: this.useCustomPrice,
      })

      this.$router.push({
        name: 'users.single.subscription',
        params: {
          userId: this.$route.params.userId,
        }
      })
    },
  },
}
</script>
