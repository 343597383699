<template>
  <fragment>
    <div class="container mt-4">
      <div class="row justify-content-center">
        <div class="col-xl-6 d-flex">
          <!-- 2fa card -->
          <two-factor-card />
        </div>
      </div>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import TwoFactorCard from '@/components/users/TwoFactorCard.vue'

export default {
  components: {
    TwoFactorCard
  },
}
</script>
