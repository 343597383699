<template>
  <multiselect
    :options="options"
    label="label"
    placeholder=""
    v-model="titleSelected"
    :preselect-first="true"
    :show-labels="false"
    :allow-empty="false"
  />
</template>

<script>
export default {
  props: {
    value: String
  },

  data () {
    return {
      options: [
        { label: 'Dr', value: 'Dr' },
        { label: 'Mr', value: 'Mr' },
        { label: 'Mrs', value: 'Mrs' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Ms', value: 'Ms' },
        { label: 'Prof', value: 'Prof' },
        { label: 'A/Prof', value: 'A/Prof' },
      ]
    }
  },

  computed: {
    titleSelected: {
      get () {
        return this.options.find(({ value }) => value === this.value)
      },

      set ({ value }) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
