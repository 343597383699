<template>
  <c-data-filters>

    <!-- search -->
    <template #basic>
      <label class="sr-only">Search</label>
      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row p-4">
      <!-- search -->
      <div class="col">
        <c-input type="text" :value="filters.search" @input="updateSearch" placeholder="Search">
          <i slot="prepend" class="fal fa-search" />
        </c-input>
      </div>
    </div>

  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'

export default {
  mixins: [useQueryParamFilters()],

  data () {
    return {
      filters: {
        search: null,
      },
    }
  },

  computed: {
    updateSearch () {
      return debounce((value) => {
        this.filters.search = value
      }, 500)
    },
  }
}
</script>
