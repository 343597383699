<template>
  <c-data-filters>
    <template #basic>
      <label class="sr-only">Search</label>

      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row">
      <div class="col-12 col-lg-7 col-xl-8">
        <div class="mb-3">
          <label>Search</label>

          <c-input type="text" :value="filters.search" @input="updateSearch">
            <i slot="prepend" class="fal fa-search" />
          </c-input>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4">
        <div class="mb-3">
          <label>Status</label>

          <c-input
            as="multiselect"
            placeholder
            :options="statusOptions"
            :preselect-first="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            v-model="status"
          >
          </c-input>
        </div>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'

export default {
  mixins: [useQueryParamFilters()],

  data () {
    return {
      filters: {
        search: null,
        status: null,
        role: null,
      },
      roleOptions: [
        { name: 'All', value: null },
        { name: 'Medical Professional', value: 'user' },
        { name: 'Training Provider', value: 'provider' },
        { name: 'Med CPD Admin', value: 'admin' }
      ],
      statusOptions: [
        { name: 'All', value: null },
        { name: 'Enabled', value: 'enabled' },
        { name: 'Disabled', value: 'disabled' },
        { name: 'Pending', value: 'pending' }
      ]
    }
  },

  computed: {
    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.filters.status)
      },

      set ({ value }) {
        this.filters.status = value
      }
    },

    role: {
      get () {
        return this.roleOptions.find(({ value }) => value === this.filters.role)
      },

      set ({ value }) {
        this.filters.role = value
      }
    },

    updateSearch () {
      return debounce((value) => {
        this.filters.search = value
      }, 1000)
    }
  }
}
</script>
