<template>
  <fragment>
    <app-header title="My Account" />

    <slot name="nav" />

    <div class="container container-max-md my-4">
      <account-details-card>
        <template #header>
          <h2>Account Details</h2>
        </template>

        <div class="row mt-4">
          <div class="col">
            <c-button v-if="user.status === 'enabled'" variant="outline-danger" @click="handleDisableUser" >Disable Account</c-button>
          </div>
          <div class="col text-end">
            <c-button type="submit" variant="primary" @click="save" validate >Save Changes</c-button>
          </div>
        </div>
      </account-details-card>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'
import AccountDetailsCard from '@/components/users/AccountDetailsCard'
import swal from 'sweetalert'

export default {
  components: {
    AccountDetailsCard,
  },

  computed: {
    user: get('users/user'),
    isA: get('users/isA'),
  },

  data () {
    return {
      originalEmail: null
    }
  },

  created () {
    this.originalEmail = this.user.email
  },

  methods: {
    updateUser: call('users/updateUser'),
    resetUser: call('users/resetUser'),
    toggleStatus: call('users/toggleStatus'),
    ...call('auth', ['logout']),

    async save () {
      if (this.originalEmail !== this.user.email) {
        await swal({
          icon: 'warning',
          text: 'You will need to verify your new email address before the update will take effect.',
          buttons: {
            ok: { text: 'Ok', value: true, visible: true, className: 'swal-button swal-button--contact btn btn-primary' },
          },
          dangerMode: true
        })
      }
      const payload = {
        title: this.user.title,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
      }
      if (this.isA('user')) {
        payload.receive_marketing = this.user.receive_marketing
        payload.participate_feedback = this.user.participate_feedback
        payload.status = this.user.status
        payload.dob = this.user.dob
      }
      if (this.isA('provider')) {
        payload.receive_marketing = this.user.receive_marketing
        payload.mobile = this.user.mobile
      }
      await this.updateUser({
        id: this.user.id,
        payload: payload
      })

      toast('Account details saved.', 'success')
    },

    async handleDisableUser () {
      await this.toggleStatus(this.user)
      await this.logout()
      this.$router.replace({ name: 'login' })
      toast('You have disabled your account, if you wish to re-enable it login and select the Enable Account option.', 'danger')
    }
  }
}
</script>
