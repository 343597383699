<template>
  <fragment>
    <app-header :title="title" />

    <ul id="horizontal-nav" class="nav nav-horizontal" v-if="user">
      <router-link tag="li" :to="{ name: 'users.single.details' }" class="nav-item" exact>
        <a class="nav-link">Account Details</a>
      </router-link>
      <router-link tag="li" :to="{ name: 'users.single.security' }" class="nav-item" exact>
        <a class="nav-link">Account Security</a>
      </router-link>
      <router-link tag="li" v-if="isEditingMedicalProfessional" :to="{ name: 'users.single.subscription' }" class="nav-item">
        <a class="nav-link">Subscription</a>
      </router-link>
      <router-link tag="li" v-if="isEditingMedicalProfessional" :to="{ name: 'users.single.learning-plans' }" class="nav-item">
        <a class="nav-link">Learning Plans</a>
      </router-link>
      <!-- Different route for different roles (diff permission/ page content/ tab label) -->
      <router-link tag="li" v-if="isEditingProvider && isEditingSelf" :to="{ name: 'users.single.profile' }" class="nav-item" exact>
        <a class="nav-link">Learner Profile</a>
      </router-link>
      <router-link tag="li" v-if="isEditingMedicalProfessional" :to="{ name: 'users.single.profile' }" class="nav-item" exact>
        <a class="nav-link">Learning Profile</a>
      </router-link>
      <router-link tag="li" v-if="isEditingProvider && (isAdmin || isProvider)" :to="{ name: 'users.single.permissions' }" class="nav-item" exact>
        <a class="nav-link">Access Permissions</a>
      </router-link>
    </ul>

    <router-view v-if="user.id"/>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { handleServerValidation } from '@/utils'
import { helperComputed, helperMethods } from '@/vuex/modules/users'
import { get } from 'vuex-pathify'

export default {
  props: {
    userId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      title: 'User'
    }
  },

  watch: {
    $route: {
      async handler () {
        try {
          await this.resetUser()

          await this.getUser(this.userId || this.$user.id)
          this.title = this.user.name
        } catch (error) {
          handleServerValidation(error, this.$refs.validation)
        }
      },
      immediate: true
    }
  },

  computed: {
    ...helperComputed,
    isA: get('users/isA'),

    isEditingSelf () {
      return this.user.id === this.$user.id
    },

    isAdmin () {
      return this.$isA('admin')
    },

    isProvider () {
      return this.$isA('provider')
    },

    isEditingProvider () {
      return this.isA('provider')
    },

    isEditingMedicalProfessional () {
      return this.isA('user')
    },
  },

  methods: {
    ...helperMethods
  }
}
</script>
