<template>
  <fragment>
    <slot name="nav" />

    <div class='col'>
      <div class="card">
        <div class="card-body">
          <h2>Self Evaluation</h2>
          <div class="mb-3">
            <label class="form-label">Review and comment on your current practice/scope/circumstances?</label>
          <p class="text-muted small">
            (eg. age of patient base, metro/regional/remote, facilities/staff available, available services in local area/catchment)
          </p>
          <c-textarea v-model="statePractice" rows="6" />
          </div>
          <div class="mb-3">
            <label class="form-label">What are your current areas of interest?</label>
          <c-textarea v-model="stateAreaOfInterest" rows="6" />
          </div>
          <div class="mb-3">
            <label class="form-label">What are your current identified strengths in your practice/skill set?</label>
            <c-textarea v-model="stateStrength" rows="6" />
          </div>
          <div class="mb-3">
            <label class="form-label">What are your current identified weaknesses in your practice/skill set?</label>
          <c-textarea v-model="stateWeakness" rows="6" />
          </div>
          <div class="mb-3">
            <label class="form-label">Do you have any personal or professional aspirations?</label>
          <c-textarea v-model="stateAspirations" rows="6"  />
          </div>
          <div class="text-end"><button type="button" class="btn btn-primary" @click="save()">Save</button></div>
        </div>
      </div>
    </div>

  </fragment>
</template>

<script>

import { toast } from '@chameleon/chameleon-vue'
import { handleServerConflict } from '@/utils'
import { call, get } from 'vuex-pathify'
import { stateMapper as userStateMapper } from '@/vuex/modules/professional-development-plans'

export default {

  mixins: [userStateMapper],

  data () {
    return {
      params: null,

    }
  },

  async created () {
    await this.getProfessionalDevelopmentPlan(this.$route.params.professionalDevelopmentPlanId)
  },

  computed: {
    professionalDevelopmentPlan: get('professionalDevelopmentPlans/professionalDevelopmentPlan'),

  },
  methods: {
    getProfessionalDevelopmentPlan: call('professionalDevelopmentPlans/getProfessionalDevelopmentPlan'),
    updateProfessionalDevelopmentPlan: call('professionalDevelopmentPlans/updateProfessionalDevelopmentPlan'),

    async save () {
      await handleServerConflict(async (headers) => {
        await this.updateProfessionalDevelopmentPlan({
          id: this.professionalDevelopmentPlan.id,
          payload: {
            practice: this.statePractice,
            area_of_interest: this.stateAreaOfInterest,
            strength: this.stateStrength,
            weakness: this.stateWeakness,
            aspirations: this.stateAspirations,
          },
          headers
        })
      })
      toast('Professional development plan was updated successfully.', 'success')
    },
  }
}

</script>
