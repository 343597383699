<template>
  <fragment>
    <div class="card">
      <div class="card-body" :class="{ 'card-body-lg': !isChangePlan }">
        <template v-if="isChangePlan">
          <h2>Change Plan</h2>

          <p class="text-muted">
            Upon changing your plan a new subscription payment will be taken.
            You will be pro-rated for your current subscription plan and credited
            any remaining time left.
          </p>
        </template>

        <template v-else>
          <h2 class="text-primary mb-5">Med CPD Premium</h2>
          <h2>Select Plan</h2>
        </template>

        <ul class="list-data list-data-plan-select no-hover no-shadow">
          <subscription-user-tier-item
            v-for="pricing in subscriptionPlan.pricings"
            :key="pricing.id"
            :pricing="pricing"
            :show-current="isChangePlan"
            v-model="selected"
          />
        </ul>

        <template v-if="!changePaymentMethod">
          <h2>Current Payment Method</h2>

          <div v-if="activeSubscription.payment_method" class="row gx-2 mb-2 text-muted">
            <div class="col">

              <div class="row">
                <div class="col-auto">
                  <img
                    :src="paymentMethodLogo"
                    alt="payment logo"
                    width="64"
                  />
                </div>
                <div class="col-auto">&bull;&bull;&bull;&bull; {{ activeSubscription.payment_method.card_last_four }}</div>
                <div class="col-auto">Expires: {{ paymentMethodExpires }}</div>
              </div>
            </div>

            <div class="col-auto">
              <a @click.prevent="changePaymentMethod = true" class="text-info">
                <i class="far fa-pencil"></i>
                Change
              </a>
            </div>
          </div>
        </template>

        <template v-else>
          <h2>Payment Details</h2>

          <p class="text-muted">
            Enter the new card details to be used for payment.
          </p>

          <add-card @tokenize="(t) => (tokenize = t)" @click="addCard" hide-button />
        </template>

        <p>
          <small class="text-muted font-italic">
            Prices shown are in Australian Dollars (AUD) and inclusive of GST.
          </small>
        </p>

        <div class="row">
          <div class="col d-flex align-items-center">
            <a @click.prevent="$router.go(-1)" class="text-muted">Cancel.</a>
          </div>

          <div class="col-auto">
            <c-button
              class="btn btn-md btn-primary"
              :disabled="!isChangingBillingFrequency"
              @click="addCard"
            >
              {{ isChangePlan ? 'Change Plan' : 'Subscribe' }}
            </c-button>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import { authMethods } from '@/vuex/helpers'
import SubscriptionUserTierItem from '@/components/subscriptions/SubscriptionUserTierItem.vue'
import AddCard from '@/components/payments/AddCard.vue'
import visaLogo from '@/assets/img/payment-logo/visa.svg'
import mastercardLogo from '@/assets/img/payment-logo/mastercard.svg'
import { http } from '@/utils'

export default {
  components: {
    SubscriptionUserTierItem,
    AddCard
  },

  data () {
    return {
      tokenize: null,
      selected: null,
      changePaymentMethod: false,
    }
  },

  async created () {
    await this.getPackages()
    await this.getActiveSubscription({}).catch(() => {})

    this.changePaymentMethod = !this.activeSubscription?.payment_method

    if (this.activeSubscription) {
      this.selected = this.activeSubscription.package_pricing_id
    }
  },

  computed: {
    subscriptionPlan: get('packages/packages'),
    activeSubscription: get('activeSubscriptions/activeSubscription'),

    isChangePlan () {
      return this.$route.name === 'my.subscription.change-plan'
    },

    paymentMethodLogo () {
      if (this.activeSubscription.payment_method?.card_brand === 'visa') {
        return visaLogo
      }

      if (this.activeSubscription.payment_method?.card_brand === 'mastercard') {
        return mastercardLogo
      }

      return null
    },

    paymentMethodExpires () {
      return `${this.activeSubscription.payment_method?.card_expiry_month}/${this.activeSubscription.payment_method?.card_expiry_year}`
    },

    isChangingBillingFrequency () {
      return this.activeSubscription?.package_pricing_id !== this.selected
    }
  },

  methods: {
    ...authMethods,
    getPackages: call('packages/getPackages'),
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),

    async addCard () {
      let paymentMethod = null
      if (this.tokenize) {
        paymentMethod = await this.tokenize()
      }

      await http.post('/subscription', {
        payment_method: paymentMethod?.id,
        package_pricing_id: this.selected,
      })

      this.authorize()

      this.$router.push({ name: 'my.subscription' })
    },
  }
}
</script>
