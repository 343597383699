<template>
  <fragment>
    <app-header title="Professional Development Plans" />
    <slot name="nav" />
    <portal to="headerRight">
      <router-link
            custom
            :to="{
              name: 'my.professional-development-plans.add.overview',
            }"
          > <button type="button" class="btn btn-primary"><i class="far fa-plus"></i> Plan</button>
          </router-link>
    </portal>

    <div class="container my-4">
      <my-professional-development-plans-list-filters v-model="params" class="mb-5" />
      <c-data-list id="development-plan-data-list" :items="groupedProfessionalDevelopmentPlans" @load="load" class="list-data no-hover">
        <template #default="{ item }">
            <h2>{{item['year']}}</h2>
                <div v-for="plan in item['plans']" :key="plan.id" class="card">
                  <router-link :to="{
                  name: 'my.professional-development-plans.single.overview',
                  params: { professionalDevelopmentPlanId: plan.id }
                }">
                  <div class="card-body">
                    <div class="row">
                      <div class="col d-flex align-items-center">{{ plan.name }}</div>
                      <div class="col-lg-3 d-flex align-items-center"><p class="text-muted small mb-0">Last updated: {{ formatDate(plan.updated_at) }}</p>
                      </div>
                    </div>
                  </div>
                </router-link>
                </div>
        </template>
      </c-data-list>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import MyProfessionalDevelopmentPlansListFilters from './MyProfessionalDevelopmentPlansListFilters.vue'
import { compareQueryParams } from '@codium/codium-vue'

export default {
  components: {
    MyProfessionalDevelopmentPlansListFilters
  },

  data () {
    return {
      params: null,
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getProfessionalDevelopmentPlans({ params, fresh: true })
    })
  },

  computed: {
    professionalDevelopmentPlans: get('users/professionalDevelopmentPlans/professionalDevelopmentPlans'),
    groupedProfessionalDevelopmentPlans () {
      const groupedPlans = {}
      this.professionalDevelopmentPlans.forEach(plan => {
        const year = plan.year.toString()
        if (!year) {
          return
        }

        if (!Object.hasOwnProperty.call(groupedPlans, year)) {
          groupedPlans[year] = []
        }
        groupedPlans[year].push(plan)
      })
      const sortedKeys = Object.keys(groupedPlans).sort((a, b) => b - a)
      const sortedArray = []

      sortedKeys.forEach(key => {
        sortedArray.push({
          year: key,
          plans: groupedPlans[key]
        })
      })
      return sortedArray
    }
  },

  beforeDestroy () {
    this.resetProfessionalDevelopmentPlans()
  },

  async created () {
    this.setUser(this.$user)
    await this.getProfessionalDevelopmentPlans()
  },

  methods: {
    setUser: call('users/setUser'),
    resetProfessionalDevelopmentPlans: call('users/professionalDevelopmentPlans/resetProfessionalDevelopmentPlans'),
    getProfessionalDevelopmentPlans: call('users/professionalDevelopmentPlans/getProfessionalDevelopmentPlans'),

    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString()
    },
    async load (done) {
      try {
        await this.getProfessionalDevelopmentPlans()
        done()
      } catch {
        done(true)
      }
    },
  }
}
</script>
