import { groupBy } from 'lodash'

export const useGroupsAbilities = ({ computedProp = 'abilitiesGrouped', abilitiesProp = 'abilities' } = {}) => {
  return {
    computed: {
      [computedProp] () {
        const grouped = groupBy(this[abilitiesProp], 'group')

        const forcedOrder = ['read', 'write', 'delete']

        Object.entries(grouped).forEach(([group, abilities]) => {
          abilities.sort((a, b) => {
            const indexA = forcedOrder.indexOf(a.title.toLowerCase())
            const indexB = forcedOrder.indexOf(b.title.toLowerCase())

            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB
            }

            return -1
          })

          // To add a border to the top of the first non-standard ability (read/write/delete) we'll find the last
          // standard ability index, and assuming we have more abilities we'll set a "spacer" prop on the next
          // ability after the last standard one.
          let lastStandardAbilityIndex = [...abilities].reverse().findIndex(({ title }) => forcedOrder.includes(title.toLowerCase()))

          if (lastStandardAbilityIndex > 0 && abilities.length > lastStandardAbilityIndex) {
            abilities.splice(++lastStandardAbilityIndex, 1, { ...abilities[lastStandardAbilityIndex], spacer: true })
          }
        })

        return grouped
      }
    }
  }
}
