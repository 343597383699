<template>
  <fragment>
    <slot name="nav" />

    <div class='col'>
      <validation-observer tag="form" class="d-flex w-100" @submit.prevent ref="validation">
      <div class="card">
        <div class="card-body">
          <h2>Overview</h2>
          <p>
            This is a guide provided to you to assist with understanding and setting out your Professional Development Plan (PDP) for the year. Using this structure will simplify the process of achieving your CPD requirements and help to improve the beneficial impact on both your personal and professional development.
          </p>
          <p>
            Start by adding a PDP name and year.
          </p>
          <div class="row">
            <div class="col-12 col-md-9 mb-3">
              <validation-provider
                tag="div"
                name="name"
                vid="name"
                class="mb-3"
                rules="required"
              >

                <label class="form-label">PDP Name</label>

                <c-input name='name' v-model="stateName"/>

                <c-help-block />
              </validation-provider>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <validation-provider
                tag="div"
                name="year"
                vid="year"
                class="mb-3"
                rules="required"
              >

                <label class="form-label">Year</label>
                <multiselect
                  :options="dateOptions"
                  :preselect-first="true"
                  :show-labels="false"
                  :allow-empty="false"
                  v-model="stateYear"
                  value="value"
                >

                </multiselect>

                <c-help-block />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-4">
        <div class="col">
          <c-button class="btn" variant="outline-danger"  @click="confirmDeleteDevelopmentPlan">
            Delete PDP
          </c-button >
        </div>
        <div class="col-auto">
          <c-button class="btn-md" @click="save">
            Save
          </c-button>
        </div>
      </div>
        </div>
      </div>
    </validation-observer>
    </div>

  </fragment>
</template>

<script>
import { toast } from '@chameleon/chameleon-vue'
import swal from 'sweetalert'
import { call, get } from 'vuex-pathify'
import { stateMapper } from '@/vuex/modules/professional-development-plans'

export default {
  mixins: [stateMapper],

  data () {
    return {
      disabled: Boolean,
      params: null,
    }
  },

  computed: {
    professionalDevelopmentPlan: get('professionalDevelopmentPlans/professionalDevelopmentPlan'),
    dateOptions () {
      const today = new Date().getFullYear()
      const options = []

      for (let i = 2020; i <= today; i++) {
        options.push(`${i}`)
      }
      return options
    },
  },
  async created () {
    await this.getProfessionalDevelopmentPlan(this.$route.params.professionalDevelopmentPlanId)
  },
  methods: {
    getProfessionalDevelopmentPlan: call('professionalDevelopmentPlans/getProfessionalDevelopmentPlan'),
    updateProfessionalDevelopmentPlan: call('professionalDevelopmentPlans/updateProfessionalDevelopmentPlan'),
    deleteProfessionalDevelopmentPlan: call('professionalDevelopmentPlans/deleteProfessionalDevelopmentPlan'),

    async save () {
      const valid = await this.$refs.validation.validate()
      if (!valid) {
        return
      }
      await this.updateProfessionalDevelopmentPlan({
        id: this.professionalDevelopmentPlan.id,
        payload: {
          name: this.professionalDevelopmentPlan.name,
          year: this.professionalDevelopmentPlan.year,
        },
      })
      toast('Professional development plan was updated successfully.', 'success')
    },
    async confirmDeleteDevelopmentPlan () {
      if (
        !await swal({
          title: 'Delete professional development plan',
          text: 'Are you sure you want to delete this professional development plan?',
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: { text: 'Yes', value: true, visible: true, closeModal: true },
          },
          dangerMode: true,
          closeOnClickOutside: false
        })
      ) {
        return
      }

      await this.deleteProfessionalDevelopmentPlan(this.professionalDevelopmentPlan.id)
      toast('Professional development plan was deleted successfully.', 'success')
      return this.$router.replace({ name: 'my.plans.professional-development-plans' })
    },
  }
}
</script>
