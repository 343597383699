<template>
  <div id="modal-alert-subscription-cancel" class="modal modal-alert fade" tabindex="-1" role="dialog" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">

          <i class="modal-alert-icon fal fa-question-circle text-info"></i>

          <h2 class="modal-alert-title">Cancel Subscription</h2>

          <p class="modal-alert-description">
            Are you sure you want to cancel your subscription? If you do you
            will lose access to your subscription features on <strong>{{ currentPeriodEnd }}</strong>.
          </p>
        </div>

        <div class="modal-footer justify-content-center">
          <button
            class="btn btn-md text-primary"
            @click="close"
          >
            Keep Subscription
          </button>

          <c-button
            class="btn btn-md btn-danger"
            @click="cancelSubscription"
          >
            Cancel Subscription
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { CModal, toast } from '@chameleon/chameleon-vue'
import { authMethods } from '@/vuex/helpers'
import { http } from '@/utils'
import dayjs from 'dayjs'

export default {
  extends: CModal,

  computed: {
    activeSubscription: get('activeSubscriptions/activeSubscription'),
    provider: get('providers/provider'),

    currentPeriodEnd () {
      return dayjs(this.activeSubscription.current_period_end)
        .format('DD/MM/YYYY')
    },

    providerSubscription () {
      return this.$isA('provider') || (this.provider.id && this.$route.name.includes('providers'))
    },
  },

  methods: {
    ...authMethods,
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),

    async cancelSubscription () {
      let params = {}

      if (this.$isA('admin')) {
        params = {
          providerId: this.$route.params.providerId,
          userId: this.$route.params.userId,
        }
      }

      await http.delete('/subscription/cancel', { params })

      await this.getActiveSubscription({
        type: this.providerSubscription ? 'providers' : 'users',
        id: this.$route.params.userId ?? this.$route.params.providerId,
      })

      this.close()
      toast('Subscription cancelled successfully', 'success')
    },
  },
}
</script>
