<template>
  <router-view v-if="user.id">
    <template #nav>
      <c-horizontal-nav prevent-overflow>
        <c-horizontal-nav-link :to="{ name: 'my.account' }">
          Account Details
        </c-horizontal-nav-link>
        <c-horizontal-nav-link :to="{ name: 'my.learner-profile' }" v-if="$isA('user')">
          Learner Profile
        </c-horizontal-nav-link>
        <c-horizontal-nav-link :to="{ name: 'my.security' }">
          Account Security
        </c-horizontal-nav-link>
        <c-horizontal-nav-link :to="{ name: 'my.subscription' }" v-if="$isA('user')">
          Subscription
        </c-horizontal-nav-link>
      </c-horizontal-nav>
    </template>
  </router-view>
</template>

<script>
import { get, call } from 'vuex-pathify'

export default {
  computed: {
    user: get('users/user')
  },

  async created () {
    await this.getUser(this.$user.id)
  },

  beforeDestroy () {
    this.resetUser()
  },

  methods: {
    getUser: call('users/getUser'),
    resetUser: call('users/resetUser'),
  }
}
</script>
