<template>
  <div id="modal-alert-subscription-resubscribe" class="modal modal-alert fade" tabindex="-1" role="dialog" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <i class="modal-alert-icon fal fa-check-circle text-success"></i>

          <h2 class="modal-alert-title">Resubscribed</h2>

          <p class="modal-alert-description">
            Your subscription cancelation has been reversed. You have
            successfully resubscribed to your existing Med CPD subscription plan.
          </p>
        </div>

        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-md btn-light"
            @click="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CModal } from '@chameleon/chameleon-vue'

export default {
  extends: CModal,
}
</script>
