<template>
  <multiselect
    placeholder="Select"
    :options="providers"
    label="name"
    track-by="id"
    v-model="providersSelected"
    v-bind="{ ...$attrs, multiple }"
    :show-labels="false"
    :close-on-select="!multiple"
  >
    <template #tag>
      <span></span>
    </template>
  </multiselect>
</template>

<script>
import { call, get } from 'vuex-pathify'

export default {
  props: {
    value: [Array, Object],
    multiple: Boolean
  },

  computed: {
    providers: get('lists/providers/providers'),

    providersSelected: {
      get () {
        if (this.multiple) {
          return this.providers.filter(({ id }) => this.value.includes(id))
        }

        return this.providers.find(({ id }) => this.value && this.value.id === id)
      },

      set (value) {
        if (this.multiple) {
          this.$emit('input', value.map(({ id }) => id))
        } else {
          this.$emit('input', value)
        }
      }
    }
  },

  async created () {
    await this.getProviders()
  },

  methods: {
    getProviders: call('lists/providers/getProviders')
  }
}
</script>
