<template>
  <fragment>
    <div class="card">
      <div class="card-body">
        <h2>Update Payment Method</h2>

        <p class="text-muted">
          Enter the new card details to be used for future subscription payments.
        </p>

        <add-card
          button-text="Save Card"
          :click="updatePaymentMethod"
          @tokenize="(t) => (tokenize = t)"
        />

        <div v-if="errorMessage" class="text-center text-danger">{{ errorMessage }}</div>
      </div>
    </div>
  </fragment>
</template>

<script>
import AddCard from '@/components/payments/AddCard.vue'
import { authMethods } from '@/vuex/helpers'
import { http } from '@/utils'
import { toast } from '@chameleon/chameleon-vue'

export default {
  components: {
    AddCard,
  },

  data () {
    return {
      tokenize: null,
      errorMessage: null,
    }
  },

  methods: {
    ...authMethods,

    async updatePaymentMethod () {
      if (!this.tokenize) {
        toast('Unable to update payment method, please try again later.', 'danger')
        return
      }

      try {
        const paymentMethod = await this.tokenize()

        await http.put('/update-payment-method', {
          payment_method: paymentMethod.id,
        })

        this.authorize()
        toast('Payment details saved.', 'success')
        this.$router.push({ name: 'my.subscription' })
        return
      } catch (err) {
        if (err.response && err.response.status === 422) {
          this.errorMessage = err.response.data.message
        }
      }
    },
  },
}
</script>
