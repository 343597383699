var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"card card-course",class:{
    'card-learning-plan-stacked': _vm.stacked,
    'card-learning-plan-horizontal': !_vm.stacked
  }},[_c('router-link',{attrs:{"custom":"","to":{
      name: _vm.learningPlan.creator ? 'my.learning-plans.single' : 'learning-plans.single',
      params: { learningPlanId: _vm.learningPlan.id },
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
return [_c('a',_vm._b({staticClass:"card-body",on:{"click":navigate}},'a',{ href: href },false),[_c('div',{staticClass:"row"},[(!_vm.hideCounts)?_c('div',{staticClass:"col-12 col-md-auto order-md-2 stats"},[_c('span',{staticClass:"stat"},[_c('i',{staticClass:"fad fa-calendar-star"}),_vm._v(" "+_vm._s(_vm.learningPlan.followers_count)+" ")]),_c('span',{staticClass:"stat"},[_c('i',{staticClass:"fad fa-thumbs-up"}),_vm._v(" "+_vm._s(_vm.learningPlan.likes_count)+" ")]),(!_vm.hideVisibility)?_c('visibility-badge',{attrs:{"following":_vm.learningPlan.following,"visibility":_vm.learningPlan.visibility}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col order-md-1 overflow-hidden"},[_c('h2',[_vm._v(_vm._s(_vm.learningPlan.name))])])]),(_vm.learningPlan.following || _vm.alwaysShowCreator)?_c('footer',[_c('p',{staticClass:"text-muted d-inline-block pe-3"},[_vm._v(" Created by: "+_vm._s(_vm.learningPlan.creator_name)+" ")]),_c('p',{staticClass:"text-muted d-inline-block"},[_vm._v(" Last updated: "+_vm._s(_vm.learningPlan.updated_time_ago)+" ")])]):_vm._e(),_c('footer',_vm._l((_vm.learningPlan.professions),function(profession){return _c('span',{key:("profession-" + profession),staticClass:"chip chip-primary"},[_vm._v(" "+_vm._s(profession)+" ")])}),0),(_vm.learningPlan.short_description)?_c('p',[_vm._v(_vm._s(_vm.learningPlan.short_description))]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }