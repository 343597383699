export const useTopicsSelected = ({ flattenedTopicsKey, resourceTopicsKey }) => {
  return {
    computed: {
      topicsSelected: {
        get () {
          return this[resourceTopicsKey].map(({ id }) => id)
        },

        set (value) {
          const topics = value.reduce((topics, id) => {
            const existing = this[resourceTopicsKey].find(topic => topic.id === id)

            if (existing !== undefined) {
              return [...topics, existing]
            }

            return [...topics, { ...this[flattenedTopicsKey].find(topic => topic.id === id), featured: topics.length === 0 }]
          }, [])

          // Maps of topic IDs and topic parent IDs.
          const idMap = topics.map(topic => topic.id)
          const parentIdMap = topics.map(topic => topic.parent_id)

          // Parents will be the ones that are in the parent ID map but not already in the ID map, as that means they
          // are already selected and we don't want them in twice.
          const parents = this[flattenedTopicsKey].filter(({ id }) => parentIdMap.includes(id) === true && idMap.includes(id) === false)

          this[resourceTopicsKey] = [
            ...topics,
            ...parents.map(({ id, name }) => ({
              id,
              name,
              parent_id: null,
              featured: false
            }))
          ]
        }
      }
    }
  }
}
