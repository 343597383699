<template>
  <fragment>
    <validation-observer tag="div" class="container container-max-md mt-4">
      <div class="card">
        <div class="card-body card-body-lg">
          <h2>Location</h2>
          <!-- location -->
          <validation-provider tag="div" class="mb-3" name="location" rules="required">
            <label class="form-label">Location</label>
            <single-select v-model="stateLocation" :options="optionsLocation"/>
            <c-help-block />
          </validation-provider>

          <h2 class="mt-5">Your Profession</h2>

          <div class="mb-3">
            <label for="" class="form-label">AHPRA number</label>
            <c-input type="text" v-model="ahpraNo" />
          </div>

          <div class="row mb-3">
            <div class="col-md">
              <c-checkbox v-if="isAdmin" class="form-check-inline" v-model="ahpraVerified">AHPRA Number Verified</c-checkbox>
            </div>
            <small v-if="formattedAhpraVerifiedDate" class="col-md-auto d-flex align-items-center text-muted">Verified on {{ formattedAhpraVerifiedDate }}</small>
          </div>

          <!-- profession -->
          <validation-provider tag="div" class="mb-3" name="profile" vid="profile_id" rules="required">
            <label class="form-label">Profession</label>
            <profession-select v-model="stateProfessionId" />
            <c-help-block />
          </validation-provider>

          <!-- primary field -->
          <validation-provider tag="div" class="mb-3" name="primary field" rules="required">
            <label class="form-label">Primary Field</label>
            <primary-field-select
              v-model="statePrimaryFieldId"
              label="name"
              key="id"
              :profession-id="stateProfessionId"
              :disabled="!stateProfessionId"
            />
            <c-help-block />
          </validation-provider>

          <!-- cpd home -->
          <validation-provider tag="div" class="mb-3" name="cpd home" vid="cpd_body_id" rules="required">
            <label class="form-label">CPD Body</label>
            <cpd-body-select
              v-model="stateCpdBodyId"
              label="name"
              key="id"
              :profession-id="stateProfessionId"
              :disabled="!stateProfessionId"
            />
            <c-help-block />
          </validation-provider>

          <!-- qualifications -->
          <validation-provider tag="div" class="mb-3" name="qualifications" rules="required" v-if="isNotStudent">
            <label class="form-label">Qualifications</label>
            <qualifications-select
              v-model="stateQualifications"
              placeholder="Add Qualifications"
              abbreviation
              :professions="[stateProfessionId]"
              :disabled="!stateProfessionId"
            />
            <ul class="list-data list-data-qualifications no-links no-hover has-button">
              <li v-for="item in stateQualifications" :key="item.id" v-bind="{ item }">
                {{ item.abbreviation }} - {{ item.name }}
                <button type="button" @click="removeQualification(item)" class="btn btn-sm text-danger">
                  <i class="far fa-times"></i>
                </button>
              </li>
            </ul>
          </validation-provider>

           <!-- country of study -->
          <validation-provider tag="div" class="mb-3" name="country of study" vif="country_of_study" rules="required">
            <label class="form-label">Country of Study</label>
            <country-select v-model="stateCountryOfStudyId" label="name" key="id" activeCountries @input="updateUniversities"/>
            <c-help-block />
          </validation-provider>

          <!-- university -->
          <validation-provider tag="div" class="mb-3" name="university" rules="required" v-if="this.user.country_of_study_id">
            <label class="form-label">University</label>
            <university-select v-model="stateUniversityId" label="name" key="id" :countryId="this.user.country_of_study_id"/>
            <c-help-block />
          </validation-provider>

          <div class="mb-3">
            <p class="form-label">Do you work in the public or private sector?</p>
            <!-- public sector -->
            <c-checkbox v-model="statePublicSector" class="form-check-inline">
              Public
            </c-checkbox>
            <!-- private sector -->
            <c-checkbox v-model="statePrivateSector" class="form-check-inline">
              Private
            </c-checkbox>
          </div>

          <h2 class="mt-5">Your Interests</h2>
          <div class="mb-3">
            <div class="search-filter">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-books-medical"></i>
                </div>
                <div class="col mb-1">
                  <div class="row">
                    <div class="col d-flex align-items-center">
                      <p class="form-label">Topics</p>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-topic-select btn-outline-primary"
                        v-c-modal:topics
                        :disabled="!stateProfessionId"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="selection">
                <div class="chip chip-primary ms-1" v-for="topic in user.topics" :key="`topic-${topic.id}`">
                  <span class="text-truncate">{{ topic.name }}</span>
                  <button type="button" class="remove-chip" @click="removeTopic(topic)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 text-end">
            <c-button type="submit" variant="primary" @click="save" validate >
              Save Changes
            </c-button>
          </div>
        </div>
      </div>
    </validation-observer>

    <select-topics-drawer v-model="topicsSelected" ref="topics" :professions="[stateProfessionId]"/>
  </fragment>
</template>

<script>
import SingleSelect from '@/components/forms/SingleSelect.vue'
import QualificationsSelect from '@/components/qualifications/QualificationsSelect.vue'
import UniversitySelect from '@/components/universities/UniversitySelect.vue'
import SelectTopicsDrawer from '@/components/topics/SelectTopicsDrawer.vue'
import CountrySelect from '@/components/forms/CountrySelect.vue'
import CpdBodySelect from '@/components/forms/CpdBodySelect.vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'
import PrimaryFieldSelect from '@/components/forms/PrimaryFieldSelect.vue'
import { useTopicsSelected } from '@/components/topics/use-topics-selected'
import { get } from 'vuex-pathify'
import { stateMapper, helperComputed } from '@/vuex/modules/users'
import { http } from '@/utils'
import { toast } from '@chameleon/chameleon-vue'
import { bus } from '../../main'
import dayjs from 'dayjs'
import { authMethods } from '@/vuex/helpers'

export default {
  mixins: [stateMapper, useTopicsSelected({ flattenedTopicsKey: 'flattenedTopics', resourceTopicsKey: 'stateTopics' })],
  components: {
    SingleSelect,
    QualificationsSelect,
    UniversitySelect,
    SelectTopicsDrawer,
    CountrySelect,
    CpdBodySelect,
    ProfessionSelect,
    PrimaryFieldSelect
  },

  data () {
    return {
      optionsLocation: [
        { label: 'Australian Capital Territory', value: 'act' },
        { label: 'New South Wales', value: 'nsw' },
        { label: 'Northern Territory', value: 'nt' },
        { label: 'Queensland', value: 'qld' },
        { label: 'Tasmania', value: 'tas' },
        { label: 'South Australia', value: 'sa' },
        { label: 'Victoria', value: 'vic' },
        { label: 'Western Australia', value: 'wa' },
      ],
    }
  },

  watch: {
    stateProfessionId: {
      handler (value) {
        if (value) {
          this.statePrimaryFieldId = null
          this.stateCpdBodyId = null
          this.stateQualifications = []
        }
      },
    },
  },

  computed: {
    ...helperComputed,
    flattenedTopics: get('lists/topics/flattened'),

    isNotStudent () {
      return this.statePrimaryField !== 'Student'
    },

    isAdmin () {
      return this.$isA('admin')
    },

    ahpraNo: {
      get () {
        return this.user.ahpra_number
      },
      set (value) {
        this.stateAhpraNumber = value
      }
    },
    ahpraVerified: {
      get () {
        return this.user.ahpra_verified_at !== null
      },
      set (value) {
        this.stateAhpraVerifiedAt = value
      }
    },
    formattedAhpraVerifiedDate () {
      if (this.user.ahpra_verified_at) {
        const localTime = dayjs.utc(this.user.ahpra_verified_at).local()
        return localTime.format('DD-MM-YYYY h:mm A')
      }
      return null
    },
  },

  methods: {
    ...authMethods,
    async save () {
      await http.put(`users/${this.user.id}/profile`, {
        location: this.user.location,
        ahpra_number: this.stateAhpraNumber,
        ahpra_verified_at: this.stateAhpraVerifiedAt,
        profession_id: this.user.profession_id,
        primary_field_id: this.user.primary_field_id,
        university_id: this.user.university_id,
        country_of_study_id: this.user.country_of_study_id,
        cpd_body_id: this.user.cpd_body_id,
        qualifications: this.user.qualifications.map(({ id }) => id),
        public_sector: this.user.public_sector,
        private_sector: this.user.private_sector,
        topics: this.user.topics.map(({ id }) => id),
      })
      await this.authorize()
      toast('Saved successfully', 'success')
    },

    removeQualification (item) {
      const qualifications = [...this.user.qualifications]
      const index = qualifications.findIndex((qualification) => qualification.id === item.id)
      qualifications.splice(index, 1)
      this.stateQualifications = qualifications
    },

    removeTopic (topic) {
      const topics = [...this.user.topics]

      // Get any children topics for the topic to be removed and remove them from the
      // array of selected topics first.
      const children = topics.filter(child => child.parent_id === topic.id)

      for (const child of children) {
        topics.splice(topics.findIndex(({ id }) => id === child.id), 1)
      }

      // Remove the selected topic.
      topics.splice(topics.findIndex(({ id }) => id === topic.id), 1)

      this.stateTopics = topics
    },

    updateUniversities () {
      bus.$emit('updateUniversities', this.user.country_of_study_id)
    }
  },
}
</script>
