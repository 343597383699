<template>
  <div class="container container-max-md mt-4">
    <component v-if="subscriptionCardComponent" :is="subscriptionCardComponent" />
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'
import MySubscriptionSingleSubscribed from '@/components/subscriptions/user/MySubscriptionSingleSubscribed.vue'
import MySubscriptionSingleUnsubscribed from '@/components/subscriptions/user/MySubscriptionSingleUnsubscribed.vue'

export default {
  async created () {
    await this.getActiveSubscription({ type: 'users', id: this.$route.params.userId })
      .catch(() => {})
  },

  beforeDestroy () {
    this.resetActiveSubscription()
  },

  computed: {
    activeSubscription: get('activeSubscriptions/activeSubscription'),

    subscriptionCardComponent () {
      if (!this.activeSubscription?.id) {
        return MySubscriptionSingleUnsubscribed
      }

      return MySubscriptionSingleSubscribed
    },
  },

  methods: {
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),
    resetActiveSubscription: call('activeSubscriptions/resetActiveSubscription'),
  },
}
</script>
