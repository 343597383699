<template>
  <router-link :to="{
    name: 'users.single.subscription.invoice',
    params: { invoiceId: invoice.id }
  }">
    <div class="row">
      <div class="col">{{ dueDate }}</div>
      <div class="col">{{ invoice.stripe_invoice_number }}</div>
      <div class="col text-end">
         <span v-if="isUnpaid" class="small text-danger me-2">Unpaid</span>
         {{ amount }}
      </div>
    </div>
  </router-link>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isUnpaid () {
      return this.invoice.status === 'Outstanding'
    },

    dueDate () {
      return dayjs(this.invoice.due_date).format('DD/MM/YYYY')
    },

    amount () {
      return (this.invoice.amount / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }
  }
}
</script>
