<template>
  <validation-observer tag="form" class="container container-max-md" @submit.prevent ref="validation" >
  <div id="modal-drawer-activity" tabindex="-1" role="dialog" class="modal fade fixed-end" aria-modal="true" >
    <div role="document" class="modal-dialog modal-dialog-vertical dw-11 dw-md-10 dw-lg-8 dw-xl-7 dw-xxl-6">
      <div class="modal-content"><div class="modal-header">
        <h2 class="modal-title">Add / Edit Activity</h2>
        <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <validation-provider tag="div" name="name" vid="name" rules="required">
            <label class="form-label">Activity Name</label>
            <c-input type="text" ref='name' class="form-control" name="name" v-model="activity.name" />
            <c-help-block/>
          </validation-provider>
        </div>
        <div class="mb-3">
          <label class="form-label">Date of activity</label>
          <c-date v-model="activity.date" :date-config="dateConfig" placeholder=""></c-date>
        </div>
        <div class="mb-3">
          <label class="form-label">Mode of activity?</label>
          <p class="text-muted small">(eg. face to face, e-learning, webinar)</p>
          <input type="text" class="form-control" v-model="activity.mode">
        </div>
        <div class="mb-3">
          <label class="form-label">Description of activity</label>
          <textarea class="form-control" v-model="activity.description"></textarea>
        </div>
        <div class="mb-3">
          <label class="form-label">Identified outcomes of the activity</label>
          <textarea class="form-control" v-model="activity.outcomes"></textarea>
        </div>
        <h4>Reflection</h4>
        <div class="mb-3">
          <label class="form-label">Were the learning outcomes met with this activity?</label>
          <input type="text" class="form-control" v-model="activity.outcomes_met">
        </div>
        <div class="mb-3">
          <label class="form-label">How relevant was this activity to your practice?</label>
          <input type="text" class="form-control" v-model="activity.relevance">
        </div>
        <div class="mb-3">
          <label class="form-label">What was learnt/achieved with this activity?</label>
          <textarea class="form-control" v-model="activity.achieved"></textarea>
        </div>
        <h4>Evaluation</h4>
        <div class="mb-3">
          <label class="form-label">What impact did this have on your knowledge/skills/practice?</label>
          <textarea class="form-control" v-model="activity.impact"></textarea>
        </div>
        <div class="mb-3">
          <label class="form-label">How might this activity contribute to improved patient outcomes?</label>
          <textarea class="form-control" v-model="activity.contribution"></textarea>
        </div>
        <h4>Hours spent on this activity</h4>
        <div class="mb-3">
          <div class="row">
            <div class="col-md-4 mb-2 mb-md-0">
              <label class="form-label">Educational Activity</label>
              <div class="input-group">
                <span class="input-group-text">EA</span>
                <c-input type="number" placeholder="0.00" class="form-control text-end no-increment-arrows" v-model="activity.educational_activity"/>
                <span class="input-group-text">hrs</span>
              </div>
            </div>
            <div class="col-md-4 mb-2 mb-md-0">
              <label class="form-label">Reviewing Performance</label>
              <div class="input-group">
                <span class="input-group-text">RP</span>
                <c-input type="number" placeholder="0.00" class="form-control text-end no-increment-arrows" v-model="activity.reviewing_performance"/>
                <span class="input-group-text">hrs</span>
              </div>
            </div>
            <div class="col-md-4 mb-2 mb-md-0">
              <label class="form-label">Measuring Outcomes</label>
              <div class="input-group">
                <span class="input-group-text">MO</span>
                <c-input type="number" placeholder="0.00" class="form-control text-end no-increment-arrows" v-model="activity.measuring_outcomes"/>
                <span class="input-group-text">hrs</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3"><label class="form-label">Hours spent reflecting and evaluating this activity</label>
          <div class="input-group"><span class="input-group-text">RP</span>
            <c-input type="number" placeholder="0.00" class="form-control text-end no-increment-arrows" v-model="activity.hours_spent"/>
            <span class="input-group-text">hrs</span>
            </div>
          </div>
        </div>
        <div class="modal-footer"><button type="button" class="btn btn-md btn-outline-danger" @click="confirmDeleteActivity(activity)">
          <i class="far fa-trash-alt"></i> Delete Activity</button>
          <button type="button" class="btn btn-md btn-outline-info me-auto" @click="duplicateActivity(activity)">
            <i class="far fa-copy"></i> Duplicate Activity</button>
            <button @click="save(activity)" type="button" class="btn btn-md btn-outline-info" >
          Save
      </button>
      <button type="button" data-bs-dismiss="modal" class="btn btn-md btn-outline-info">
          Close
      </button>
    </div>
    </div>
  </div>
</div>
</validation-observer>
</template>

<script>
import { http } from '@/utils'
import swal from 'sweetalert'
import { toast } from '@chameleon/chameleon-vue'

export default {

  props: {
    activity: Object,
    goalId: {
      type: [Number, String],
      required: true
    },
  },

  data () {
    return {
      dateConfig: {
        dateFormat: 'd M Y',
        altFormat: 'd M Y',
      }
    }
  },
  methods: {
    async save (activity) {
      const valid = await this.$refs.validation.validate()
      if (!valid) {
        return
      }
      if (!activity.id) {
        activity.goal_id = this.goalId
        await http.post(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals/activities`, activity)
        this.$emit('activity-changed')
        document.querySelector('[data-bs-dismiss="modal"]').click()
        return
      }
      try {
        await http.put(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals/activities`, activity)
        document.querySelector('[data-bs-dismiss="modal"]').click()
      } catch (error) {
      }
    },
    async confirmDeleteActivity (activity) {
      if (!activity.id) {
        return document.querySelector('[data-bs-dismiss="modal"]').click()
      }
      if (
        !(await swal({
          title: 'Delete activity',
          text: 'Are you sure you want to delete this activity?',
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: {
              text: 'Yes',
              value: true,
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
        }))
      ) {
        return
      }
      await http.delete(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals/activities/${activity.id}`)
      swal.close()
      document.querySelector('[data-bs-dismiss="modal"]').click()
      this.$emit('activity-changed')
      toast('Activity has been deleted.', 'info')
    },
    async duplicateActivity (activity) {
      activity.goal_id = this.goalId
      await http.post(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/goals/activities`, activity)
      document.querySelector('[data-bs-dismiss="modal"]').click()
      this.$emit('activity-changed')
      toast('Activity has been dupliacted.', 'info')
    }
  }
}
</script>
