<template>
  <div class="card w-100">
    <validation-observer tag="form" class="card-body card-body-lg d-flex flex-column" @submit.native.prevent ref="validation" >
      <h2 class="card-title">2-Factor Authentication</h2>

      <p class="text-info">
        <i class="far fa-info-circle"></i>
        Using a second authentication method helps keep your account secure.
      </p>

      <validation-provider tag="div" class="mb-4" vid="two_factor_required" name="two factor" rules="required" >
        <p class="label">Use 2-factor when logging in?</p>
        <c-radio name="2fa-opt-in" id="2fa-yes" inline v-model="stateTwoFactorRequired" :value="true" :disabled="!editingSelf">
          Yes
        </c-radio>
        <c-radio name="2fa-opt-in" id="2fa-no" inline v-model="stateTwoFactorRequired" :value="false" >
          No
        </c-radio>
        <c-help-block/>
      </validation-provider>

      <validation-provider v-if="stateTwoFactorRequired && editingSelf" tag="div" class="mb-4" name="two factor method" rules="required" >
        <p class="label">
          Set your preferred method for 2-factor authentication.
        </p>
        <c-radio inline name="2fa-method-preferred" id="2fa-method-email" v-model="stateTwoFactorMethodPreferred" value="email" >
          Email
        </c-radio>
        <c-radio inline name="2fa-method-preferred" id="2fa-method-app" v-model="stateTwoFactorMethodPreferred" value="app" >
          Authenticator App
        </c-radio>
        <c-help-block/>
      </validation-provider>

      <p v-if="user.two_factor_method_enabled === 'app'" class="text-muted small"><strong>Note:</strong> If you need to add your app authentication key to a different device please click <a class="strong text-decoration-underline" href="" @click.prevent="invalidate">here</a> to setup a new device and invalidate your existing app token.
      </p>

      <div class="text-end mt-auto">
        <c-button @click="save" validate>
          Save Changes
        </c-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { call } from 'vuex-pathify'
import { helperComputed, helperMethods } from '@/vuex/modules/users'
import { userStateMapper } from '@/vuex/helpers'
import { toast } from '@chameleon/chameleon-vue'

export default {
  mixins: [userStateMapper],

  computed: {
    ...helperComputed,

    editingSelf () {
      return this.$user.id === this.user.id
    },
  },

  methods: {
    ...helperMethods,

    authorize: call('auth/authorize'),
    updateTwoFactor: call('auth/updateTwoFactor'),
    invalidateTwoFactorAppToken: call('auth/invalidateTwoFactorAppToken'),

    async save () {
      const payload = {
        two_factor_required: this.user.two_factor_required
      }
      if (this.editingSelf) {
        payload.two_factor_method_preferred = this.user.two_factor_method_preferred
      }
      await this.updateTwoFactor({
        id: this.user.id,
        payload
      })
      toast('2-factor successfully updated')
      if (this.editingSelf) {
        await this.authorize()

        if (this.user.two_factor_required && this.user.two_factor_method_preferred !== this.user.two_factor_method_enabled) {
          switch (this.user.two_factor_method_preferred) {
            case 'app':
              return this.$router.replace({ name: 'two-factor.setup.app' })
            case 'email':
              return this.$router.replace({ name: 'two-factor.setup.email' })
          }
        }
      }
    },

    async invalidate () {
      if (this.editingSelf) {
        await this.invalidateTwoFactorAppToken()
        this.$router.replace({ name: 'two-factor.setup.app' })
      }
    }
  }
}
</script>
