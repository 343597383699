<template>
  <fragment>
    <div class="container mt-4">
      <my-learning-plans-list-filters v-model="params"/>
      <c-data-list
        :items="learningPlans"
        @load="load"
        class="results-list"
        remove-default-class
      >
        <template #default="{ item: learningPlan }">
          <learning-plan-card v-bind="{ learningPlan }"/>
        </template>
      </c-data-list>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import LearningPlanCard from '@/components/learning-plans/LearningPlanCard.vue'
import MyLearningPlansListFilters from './MyLearningPlansListFilters.vue'
import { compareQueryParams } from '@codium/codium-vue'

export default {
  components: {
    LearningPlanCard,
    MyLearningPlansListFilters
  },

  data () {
    return {
      params: null,
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getLearningPlans({ params, fresh: true })
    })
  },

  computed: {
    learningPlans: get('users/learningPlans/learningPlans')
  },

  beforeDestroy () {
    this.resetLearningPlans()
  },

  methods: {
    resetLearningPlans: call('users/learningPlans/resetLearningPlans'),
    getLearningPlans: call('users/learningPlans/getLearningPlans'),

    async load (done) {
      const { params } = this
      try {
        await this.getLearningPlans({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
