<template>
  <div class="container container-max-md mt-4">
    <validation-observer slim>
      <account-details-card hide-notification-settings>
        <template #header>
          <h2>Account Details</h2>
        </template>
        <p class="text-muted small" v-if="user.last_login_at">
          <strong>Last login:</strong> {{ user.last_login_at | date({ format: 'DD/MM/YYYY [at] h:mm a' }) }} from {{ user.last_login_ip }}
        </p>

        <div class="row mt-4">
          <div class="col">
            <c-button v-if="user.status === 'disabled'" variant="outline-success" @click="handleToggleStatus" >Enable User</c-button>
            <c-button v-else variant="outline-danger" @click="handleToggleStatus" >Disable User</c-button>
          </div>
          <div class="col-auto">
            <c-button type="submit" variant="primary" @click="save" validate >Save Changes</c-button>
          </div>
        </div>
      </account-details-card>
    </validation-observer>

    <change-password-card v-if="user.id === $user.id" />
  </div>
</template>

<script>
import { toast } from '@chameleon/chameleon-vue'
import { helperComputed, helperMethods } from '@/vuex/modules/users'
import ChangePasswordCard from '@/components/users/ChangePasswordCard'
import swal from 'sweetalert'
import { call, get } from 'vuex-pathify'
import AccountDetailsCard from '@/components/users/AccountDetailsCard'

export default {
  components: {
    AccountDetailsCard,
    ChangePasswordCard
  },

  computed: {
    ...helperComputed,
    isA: get('users/isA'),

    isAdminEditingProviderUser () {
      return this.$isA('admin') && this.isA('provider')
    }
  },

  data () {
    return {
      originalEmail: null
    }
  },

  created () {
    this.originalEmail = this.user.email
  },

  methods: {
    ...helperMethods,
    ...call('users', ['toggleStatus']),

    async save () {
      const payload = {
        title: this.user.title,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        dob: this.user.dob,
        mobile: this.user.mobile,
      }
      if (this.$isA('admin') && this.user.provider) {
        payload.provider_id = this.user.provider.id
      }

      await this.updateUser({
        id: this.user.id,
        payload: payload
      })
      toast('Successfully saved', 'success')
    },

    async handleToggleStatus () {
      await this.toggleStatus({
        id: this.user.id,
      })
      toast('Successfully updated user status', 'success')
    },

    async destroy () {
      if (
        !await swal({
          text: 'Are you sure you want to delete this user?',
          buttons: {
            cancel: { visible: true },
            confirm: { visible: true }
          },
          dangerMode: true
        })
      ) {
        return
      }

      await this.deleteUser(this.user.id)

      toast('Successfully deleted user.', 'success')

      this.$router.replace({ name: 'users' })
    }
  }
}
</script>
