<template>
  <c-data-filters>

    <!-- search -->
    <template #basic>
      <label class="sr-only">Search</label>
      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row p-4">
      <!-- search -->
      <div class="col">
        <c-input type="text" :value="filters.search" @input="updateSearch" placeholder="Search">
          <i slot="prepend" class="fal fa-search" />
        </c-input>
      </div>
      <!-- created by me -->
      <div class="col-auto d-flex align-items-center ms-3">
        <div class="form-check form-switch">
          <input type="checkbox" id="by-me" class="form-check-input" v-model="ownedComputed">
          <label for="by-me" class="form-check-label">Created by me</label>
        </div>
      </div>
    </div>

  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'

export default {
  mixins: [useQueryParamFilters()],

  data () {
    return {
      filters: {
        search: null,
        owned: 'false'
      },
    }
  },

  computed: {
    updateSearch () {
      return debounce((value) => {
        this.filters.search = value
      }, 500)
    },

    ownedComputed: {
      get () {
        return this.filters.owned === 'true'
      },

      set (value) {
        value ? this.filters.owned = 'true' : this.filters.owned = 'false'
      }
    }
  }
}
</script>
