<template>
  <fragment>
    <div class="card">
      <div class="card-body card-body-lg">
        <h2 class="text-primary mb-5">Med CPD Premium</h2>

        <div class="row mt-4 mb-3">
          <div class="col-auto">
            <p class="h4 text-primary">$15 /month</p>
          </div>
          <div class="col ps-4">
            <p class="text-muted small">or $150 per year (Save $30)</p>
          </div>
        </div>

        <p class="strong">Free 30 day trial, cancel at any time.</p>

        <p class="text-muted">
          Elevate your career with Med CPD Premium, your gateway to superior
          professional development. For just $15 a month, access a world of
          exclusive features: <br/><br />

          <b>Unlimited Course Access:</b><br />
          Explore a diverse library of courses and content that meets all of your needs.<br /><br />

          <b>Personalised Learning Plans:</b><br />
          Organise and plan your CPD journey with your personalised tailored learning plans.<br /><br />

          <b>Professional Development Plan Management:</b><br />
          Grow your career effortlessly with guided plans that track your activities to maximise your investment.<br /><br />

          <b>Support the Med CPD community:</b><br />
          Invest in a community that is invested in you, be part of a community that exists entirely to support your career in health.<br />
          Invest in Med CPD Premium today and unlock the full potential of your career in health.
        </p>

        <p class="mt-3">
          <small class="text-muted font-italic">
            Prices shown are in Australian Dollars (AUD) and inclusive of GST.
          </small>
        </p>

        <div>
          <router-link class="btn btn-primary" :to="subscribeRoute">
            Subscribe
          </router-link>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  computed: {
    subscriptionPackage: get('packages/packages'),

    subscribeRoute () {
      if (this.$isA('admin')) {
        return {
          name: 'users.single.subscription.subscribe',
          params: { userId: this.$route.params.userId }
        }
      }

      return { name: 'my.subscription.subscribe' }
    },
  },
}
</script>
