<template>
  <imask-input
    v-model="value"
    mask="0000 000 000"
    :unmask="true"
    :class="{ 'is-invalid': invalid }"
    @input="input"
    class="form-control"
  />
</template>

<script>
import { IMaskComponent } from 'vue-imask'

export default {
  name: 'InputPhone',

  components: {
    'imask-input': IMaskComponent,
  },

  props: {
    value: undefined,
    invalid: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    input (input) {
      this.$emit('input', input)
    },
  },
}
</script>
