<template>
  <component v-if="subscriptionCardComponent" :is="subscriptionCardComponent" />
</template>

<script>
import { call, get } from 'vuex-pathify'
import MySubscriptionSingleSubscribed from '@/components/subscriptions/user/MySubscriptionSingleSubscribed.vue'
import MySubscriptionSingleUnsubscribed from '@/components/subscriptions/user/MySubscriptionSingleUnsubscribed.vue'

export default {
  async created () {
    await this.getActiveSubscription({}).catch(() => {})
  },

  computed: {
    activeSubscription: get('activeSubscriptions/activeSubscription'),

    subscriptionCardComponent () {
      if (!this.activeSubscription?.id) {
        return MySubscriptionSingleUnsubscribed
      }

      return MySubscriptionSingleSubscribed
    },
  },

  methods: {
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),
  },
}
</script>
