<template>
  <div>
    <div v-show="loading" class="text-center mb-4">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-show="!loading" class="card card-payment payment-details">
      <div class="card-body">

        <div class="row mb-2">
          <div class="col d-flex align-items-center">
            <h3 class="mb-0">Card Details</h3>
          </div>
          <div class="col-auto">
            <img class="payment-method-logo" :src="cardBrandImg" :alt="cardBrand">
          </div>
        </div>

        <div class="mb-3">
          <label>Card Number</label>
          <div ref="cardNumber" class="form-control"></div>
          <span class="invalid-feedback"></span>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label>Expires</label>
              <div ref="cardExpiry" class="form-control"></div>
              <span class="invalid-feedback"></span>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label>CVC</label>
              <div ref="cardCvc" class="form-control"></div>
              <span class="invalid-feedback"></span>
            </div>
          </div>
        </div><!-- /.row -->

        <c-button
          v-if="!hideButton"
          class="btn btn-primary btn-card-action"
          @click="click"
        >
          {{ buttonText }}
        </c-button>
      </div>
    </div>

    <div class="row gx-2 justify-content-end">
      <div class="col-auto text-muted">Accepted:</div>
      <div class="col-auto">
          <img src="@/assets/img/payment-logo/paymark-visa.svg" width="32" alt="visa">
      </div>
      <div class="col-auto">
        <img src="@/assets/img/payment-logo/paymark-mastercard.svg" width="32" alt="mastercard">
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import visaLogo from '@/assets/img/payment-logo/visa-light.svg'
import mastercardLogo from '@/assets/img/payment-logo/mastercard-light.svg'

export default {
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Subscribe',
    },
    click: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      loading: false,
      stripe: null,
      elements: null,
      elementClasses: {
        focus: 'focus',
        empty: 'empty',
        invalid: 'is-invalid'
      },
      elementStyles: {
        base: {
          fontFamily: 'Poppins, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: 1.6,
          letterSpacing: '4px',
          color: '#FFFFFF',
          '::placeholder': {
            color: '#767676'
          }
        }
      },
      cardElements: [
        { name: 'cardNumber', placeholder: '•••• •••• •••• ••••' },
        { name: 'cardExpiry', placeholder: 'MM / YY' },
        { name: 'cardCvc', placeholder: '•••' }
      ],
      cardBrand: null
    }
  },

  async created () {
    this.loading = true

    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)

    // TODO: Add fonts
    this.elements = this.stripe.elements({})

    this.cardElements.forEach((element) => {
      this[element.name] = this.elements.create(element.name, {
        style: this.elementStyles,
        classes: this.elementClasses,
        placeholder: element.placeholder
      })
    })

    this.cardElements.forEach((element) => {
      this[element.name].mount(this.$refs[element.name])
    })

    this.cardNumber.on('change', ({ brand, error }) => {
      this.cardBrand = brand

      this.handleElementError('cardNumber', error)
    })

    this.cardExpiry.on('change', ({ error }) => {
      this.handleElementError('cardExpiry', error)
    })

    this.cardCvc.on('change', ({ error }) => {
      this.handleElementError('cardCvc', error)
    })

    this.loading = false

    this.$emit('tokenize', this.tokenize)
  },

  computed: {
    cardBrandImg () {
      if (this.cardBrand === 'visa') {
        return visaLogo
      }

      if (this.cardBrand === 'mastercard') {
        return mastercardLogo
      }

      return null
    }
  },

  methods: {
    handleElementError (element, error) {
      if (error) {
        this.$refs[element].nextSibling.textContent = error.message
      } else {
        this.$refs[element].nextSibling.textContent = ''
      }
    },

    async tokenize () {
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber,
      })

      if (error) {
        throw new Error(error)
      }

      return paymentMethod
    }
  },
}
</script>
