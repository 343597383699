<template>
  <c-data-filters>
    <template #basic>
      <label class="sr-only">Search</label>

      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row">
      <div class="col-12 col-lg-4 col-xl-6">
        <div class="mb-3">
          <label>Search</label>

          <c-input type="text" :value="filters.search" @input="updateSearch">
            <i slot="prepend" class="fal fa-search" />
          </c-input>
        </div>
      </div>

      <div class="col-12 col-lg-4 col-xl-6">
        <div class="mb-3">
          <label>Account Type</label>

          <c-input
            as="multiselect"
            placeholder
            :options="roleOptions"
            :preselect-first="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            v-model="role"
          >
          </c-input>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 col-xl-6">
        <div class="mb-3">
          <label>Plan</label>

          <subscription-plan-select v-model="plan" />
        </div>
      </div>

      <div class="col-12 col-lg-4 col-xl-6">
        <div class="mb-3">
          <label>Account Status</label>

          <c-input
            as="multiselect"
            placeholder
            :options="statusOptions"
            :preselect-first="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            v-model="status"
          >
          </c-input>
        </div>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import SubscriptionPlanSelect from '@/components/subscriptions/SubscriptionPlanSelect.vue'
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    SubscriptionPlanSelect,
  },

  data () {
    return {
      filters: {
        search: null,
        status: null,
        plan: null,
        role: null,
      },
      roleOptions: [
        { name: 'All', value: null },
        { name: 'Medical Professional', value: 'user' },
        { name: 'Training Provider', value: 'provider' },
      ],
      planOptions: [],
      statusOptions: [
        { name: 'All', value: null },
        { name: 'Active', value: 'active' },
        { name: 'Inactive', value: 'inactive' },
      ]
    }
  },

  created () {
    if (this.filters.plan) {
      this.filters.plan = Array.isArray(this.filters.plan)
        ? this.filters.plan.map((id) => parseInt(id))
        : [parseInt(this.filters.plan)]
    }
  },

  computed: {
    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.filters.status)
      },

      set ({ value }) {
        this.filters.status = value
      }
    },

    role: {
      get () {
        return this.roleOptions.find(({ value }) => value === this.filters.role)
      },

      set ({ value }) {
        this.filters.role = value
      }
    },

    plan: {
      get () {
        return this.filters.plan
      },

      set (value) {
        this.filters.plan = value
      }
    },

    updateSearch () {
      return debounce((value) => {
        this.filters.search = value
      }, 1000)
    }
  }
}
</script>
