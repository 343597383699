<template>
   <fragment>
    <app-header title="Edit Professional Development Plan" />
    <div class="container">
      <div class="row gx-5">
        <router-view>
          <template #nav>
            <div class="col-lg-3">
              <nav id="navbar-secondary-scroll" class="navbar navbar-expand-lg navbar-secondary">
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarInnerNav" aria-controls="navbarInnerNav" aria-expanded="false" aria-label="Toggle navigation"><i class="far fa-bars"></i> Navigation</button>
                  <div class="collapse navbar-collapse" id="navbarInnerNav">
                    <ul class="nav nav-page-secondary flex-column">
                      <li class="nav-item" :class="{ 'active': $route.name === 'my.professional-development-plans.single.overview' }">
                        <router-link class="nav-link" :to="{ name: 'my.professional-development-plans.single.overview' }">Overview</router-link>
                      </li>
                      <li class="nav-item" :class="{ 'active': $route.name === 'my.professional-development-plans.single.self-evaluation' }">
                        <router-link class="nav-link" :to="{ name: 'my.professional-development-plans.single.self-evaluation' }">Self Evaluation</router-link>
                      </li>
                      <li class="nav-item" :class="{ 'active': $route.name === 'my.professional-development-plans.single.identified-goals' }">
                        <router-link class="nav-link" :to="{ name: 'my.professional-development-plans.single.identified-goals' }">Identified Goals</router-link>
                      </li>
                      <li class="nav-item" :class="{ 'active': $route.name === 'my.professional-development-plans.single.annual-reflection' }">
                        <router-link class="nav-link" :to="{ name: 'my.professional-development-plans.single.annual-reflection' }">Annual Reflection</router-link>
                      </li>
                      <li class="nav-item" :class="{ 'active': $route.name === 'my.professional-development-plans.single.annual-cpd-summary' }">
                        <router-link class="nav-link" :to="{ name: 'my.professional-development-plans.single.annual-cpd-summary' }">Annual CPD Summary</router-link>
                      </li>
                    </ul>
                </div>
              </nav>
            </div>
          </template>
        </router-view>
      </div>
    </div>
  </fragment>
</template>
<script>

export default {

  data () {
    return {
      ready: false,
      filters: {
        keywords: null
      }
    }
  },

  computed: {
    isOrganisationUser () {
      return this.$user.role.group === 'Organisation'
    },

    tabs () {
      const tabs = [
        { title: 'Overview', id: 'user-activity', description: 'This report is designed to provide detailed information around the device usage for each user within each department in your organisation.' },
        { title: 'Self Evaluation', id: 'user-list', description: 'A list of the users within your organisation by role and department.' },
        { title: 'Identified Goals', id: 'device-list', description: 'A list of the devices within an organisation.' },
        { title: 'Annual Reflection', id: 'organisation-summary', description: 'A summary report for the organisation and departments including a breakdown of users by type, the number of alerts and tests for the reporting period and total number of deactivations during this time.' },
        { title: 'Anual CPD Summary', id: 'event-overview', description: 'Summary of alerts by device for the selected period.' },
        { title: 'Management user list', id: 'management-user-list', description: 'A list of the users with escalation groups and escalation contacts within your organisation by role and department.' },
        { title: 'Escalation groups', id: 'escalation-groups', description: 'A list of all escalation groups and escalation contacts.' },
      ]
      return tabs
    }
  },
}
</script>
