<template>
  <fragment>
    <app-header title="Add Med CPD Admin" />

    <validation-observer
      tag="form"
      class="container container-max-sm"
      @submit.prevent
      ref="validation"
    >
      <form class="card" @submit.prevent>
        <div class="card-body card-body-lg">
          <p>
            When adding a Med CPD user they will be sent an email to create an
            account and will have full admin access.
          </p>
          <div class="mb-3">
            <p class="form-label">First Name</p>
            <div class="row">
              <validation-provider
                tag="div"
                class="col-5 col-sm-4"
                name="title"
                rules="required"
              >
                <label class="visually-hidden">Title</label>
                <title-select v-model="title" />
                <c-help-block />
              </validation-provider>

              <validation-provider
                tag="div"
                class="col"
                name="first name"
                rules="required"
              >
                <label class="visually-hidden">First Name</label>
                <c-input type="text" v-model="firstName" />
                <c-help-block />
              </validation-provider>
            </div>
          </div>

          <validation-provider
            tag="div"
            class="mb-3"
            name="last name"
            rules="required"
          >
            <label class="form-label">Last Name</label>
            <c-input type="text" v-model="lastName" />
            <c-help-block />
          </validation-provider>

          <validation-provider
            tag="div"
            class="mb-4"
            name="email"
            rules="required|email"
          >
            <label class="form-label">Email</label>
            <c-input type="email" v-model="email" />
            <c-help-block />
          </validation-provider>

          <div class="text-end">
            <c-button variant="primary" @click="submit" validate>
              Add User
            </c-button>
          </div>
        </div>
      </form>
    </validation-observer>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { helperMethods, helperComputed } from '@/vuex/modules/users'
import { toast } from '@chameleon/chameleon-vue'
import TitleSelect from '@/components/users/TitleSelect.vue'

export default {
  computed: helperComputed,

  components: {
    TitleSelect
  },

  data () {
    return {
      title: 'Dr',
      firstName: null,
      lastName: null,
      email: null,
    }
  },

  created () {
    this.resetUser()
  },

  beforeDestroy () {
    this.resetUser()
  },

  methods: {
    ...helperMethods,

    async submit () {
      try {
        await this.storeUser({
          title: this.title,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
        })

        toast('Med CPD admin user was successfully added.', 'success')

        this.$router.push({ name: 'users' })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
