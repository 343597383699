<template>
  <article
    class="card card-course"
    :class="{
      'card-learning-plan-stacked': stacked,
      'card-learning-plan-horizontal': !stacked
    }"
  >
    <router-link
      custom
      v-slot="{ href, navigate }"
      :to="{
        name: learningPlan.creator ? 'my.learning-plans.single' : 'learning-plans.single',
        params: { learningPlanId: learningPlan.id },
      }"
    >
      <a v-bind="{ href }" @click="navigate" class="card-body">
        <div class="row">
          <div class="col-12 col-md-auto order-md-2 stats" v-if="!hideCounts">
            <span class="stat">
              <i class="fad fa-calendar-star"></i>
              {{ learningPlan.followers_count }}
            </span>
            <span class="stat">
              <i class="fad fa-thumbs-up"></i>
              {{ learningPlan.likes_count }}
            </span>
            <visibility-badge
              v-if="!hideVisibility"
              :following="learningPlan.following"
              :visibility="learningPlan.visibility"
            />
          </div>
          <div class="col order-md-1 overflow-hidden">
            <h2>{{ learningPlan.name }}</h2>
          </div>
        </div>
        <footer v-if="learningPlan.following || alwaysShowCreator">
          <p class="text-muted d-inline-block pe-3">
            Created by: {{ learningPlan.creator_name }}
          </p>
          <p class="text-muted d-inline-block">
            Last updated: {{ learningPlan.updated_time_ago }}
          </p>
        </footer>
        <footer>
          <span v-for="profession in learningPlan.professions" :key="`profession-${profession}`" class="chip chip-primary">
            {{ profession }}
          </span>
        </footer>
        <p v-if="learningPlan.short_description">{{ learningPlan.short_description }}</p>
      </a>
    </router-link>
  </article>
</template>

<script>
import VisibilityBadge from './VisibilityBadge.vue'

export default {
  props: {
    learningPlan: Object,
    hideVisibility: Boolean,
    alwaysShowCreator: Boolean,
    stacked: {
      type: Boolean,
      default: false
    },
    hideCounts: {
      type: Boolean,
      default: false
    }
  },

  components: {
    VisibilityBadge
  }
}
</script>
