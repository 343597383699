import Provider from '@/views/users/provider/UsersList.vue'
import Admin from '@/views/users/UsersList.vue'

const components = {
  provider: Provider,
  admin: Admin,
}

export default {
  functional: true,

  render (h, context) {
    const { role } = context.parent.$store.state.auth.user

    return h(components[role.name], context.data, context.children)
  }
}
