<template>
  <validation-observer class="card" @submit.prevent>
    <div class="card-body">
      <div class="row">
        <div class="col-xl-8">
          <div class="mb-3">
            <validation-provider
              tag="div"
              name="learning plan name"
              vid="name"
              class="mb-3"
              rules="required"
            >

              <label class="form-label">Learning Plan Name</label>

              <c-input v-model="stateName" v-bind="{ disabled }"/>

              <c-help-block />
            </validation-provider>
          </div>

          <validation-provider
            tag="div"
            name="short description"
            vid="short_description"
            class="mb-3"
            rules="max:280"
          >
            <label class="form-label">Short Description</label>

            <c-textarea v-model="stateShortDescription" />

            <c-help-block/>

            <div class="row gx-2 form-text">
              <div class="col">
                This is shown to users in the learning plans directory
              </div>
              <div class="col-auto">
                {{ shortDescriptionCharacters }} of 280 characters
              </div>
            </div>
          </validation-provider>

          <div class="mb-3">
            <label class="form-label">Description</label>

            <quill-textarea v-model="stateDescription" />
          </div>

          <validation-provider class="mt-3" name="professions" vid="professions" rules="required">
            <div class="search-filter">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-user-tie"></i>
                </div>
                <div class="col mb-1">
                  <div class="row">
                    <div class="col d-flex align-items-center">
                      <p class="form-label">Focus Professions</p>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-topic-select btn-outline-primary"
                        v-c-modal:professions
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.row -->

              <div class="selection">
                <div
                  class="chip chip-primary ms-1"
                  v-for="profession in professionsFiltered"
                  :key="`profession-${profession.id}`"
                >
                  <span class="text-truncate">{{ profession.name }}</span>
                  <button type="button" class="remove-chip" @click="removeProfession(profession)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <c-input type="hidden" v-model="stateProfessions" :disabled="disabled"/>
            <c-help-block/>
          </validation-provider>

          <div class="search-filter">
            <div class="row">
              <div class="col-auto d-flex align-items-end">
                <i class="filter-icon fal fa-fw fa-hospital-user"></i>
              </div>
              <div class="col mb-1">
                <div class="row">
                  <div class="col d-flex align-items-center">
                    <p class="form-label">Focus Primary Fields</p>
                  </div>
                  <div class="col-auto">
                    <button
                      type="button"
                      class="btn btn-topic-select btn-outline-primary"
                      v-c-modal:primaryFields
                      :disabled="!professionsSelected.length"
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="selection">
              <div
                class="chip chip-primary ms-1"
                v-for="({ id, name }, index) in primaryFieldsOrdered"
                :key="`primary-field-${index}`"
              >
                <span class="text-truncate">{{ name }}</span>
                <button type="button" class="remove-chip" @click="removePrimaryField(id)">
                  <i class="far fa-times"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="search-filter">
              <div class="row">
                <div class="col-auto d-flex align-items-end">
                  <i class="filter-icon fal fa-fw fa-books-medical"></i>
                </div>
                <div class="col mb-1">
                  <div class="row">
                    <div class="col d-flex align-items-center">
                      <p class="form-label">Focus Topics</p>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-topic-select btn-outline-primary"
                        v-c-modal:topics
                        :disabled="!professionsSelected.length"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.row -->

              <div class="selection">
                <div class="chip chip-primary ms-1" v-for="topic in topicsOrdered" :key="`topic-${topic.id}`">
                  <span class="text-truncate">{{ topic.name }}</span>
                  <button type="button" class="remove-chip" @click="removeTopic(topic)">
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <validation-provider class="mb-3" name="visibility" vid="visibility" rules="required">
            <label class="form-label">Visibility</label>

            <single-select v-model="stateVisibility" :disabled="disabled" :options="visibilityOptions"/>
            <c-help-block/>
          </validation-provider>

          <template v-if="stateVisibility !== 'private'">
            <p class="small text-muted mb-0">
              {{ learningPlan.public_url }}
            </p>

            <div class="share-container" v-if="newLearningPlan === false">
              <div class="row hx-2">
                <div class="col d-flex align-items-center">
                  <h3>Share</h3>
                </div>
                <div class="col-auto d-flex align-items-center">
                  <ul class="share-links">
                    <li>
                      <a :href="facebookShareUrl" target="_blank" @click="logShareActivity('facebook')">
                        <i class="fab fa-fw fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a :href="twitterShareUrl" target="_blank" @click="logShareActivity('twitter')">
                        <i class="fab fa-fw fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a :href="linkedinShareUrl" target="_blank" @click="logShareActivity('linkedin')">
                        <i class="fab fa-fw fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a :href="learningPlan.public_url" @click.prevent="copyPublicUrl">
                        <i class="far fa-fw fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <c-button class="btn-md" variant="danger" v-if="disabled === false && newLearningPlan === false" @click="confirmDeleteLearningPlan">
            Delete Plan
          </c-button>
        </div>

        <div class="col-auto">
          <c-button class="btn-md" @click="store" v-if="newLearningPlan === true" validate>
            Create Plan
          </c-button>
          <c-button class="btn-md" @click="save" v-if="newLearningPlan === false" validate>
            Save Changes
          </c-button>
        </div>
      </div>
    </div>

    <select-professions-drawer
      v-model="professionsSelected"
      ref="professions"
    />

    <select-primary-fields-drawer
      v-model="primaryFieldsSelected"
      ref="primaryFields"
      :professions="professionsSelected"
    />

    <select-topics-drawer
      v-model="topicsSelected"
      ref="topics"
      :professions="professionsSelected"
    />
  </validation-observer>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { stateMapper } from '@/vuex/modules/learning-plans'
import QuillTextarea from '@/components/forms/QuillTextarea'
import SingleSelect from '@/components/forms/SingleSelect'
import SelectProfessionsDrawer from '@/components/professions/SelectProfessionsDrawer.vue'
import SelectPrimaryFieldsDrawer from '@/components/primary-fields/SelectPrimaryFieldsDrawer.vue'
import SelectTopicsDrawer from '@/components/topics/SelectTopicsDrawer.vue'
import { useTopicsSelected } from '@/components/topics/use-topics-selected'
import { sortBy } from 'lodash'
import { toast } from '@chameleon/chameleon-vue'
import { handleServerConflict } from '@/utils'
import swal from 'sweetalert'
import { copyToClipboard } from '@/utils/functions/copy-to-clipboard'

export default {
  mixins: [stateMapper, useTopicsSelected({ flattenedTopicsKey: 'flattenedTopics', resourceTopicsKey: 'stateTopics' })],

  components: {
    QuillTextarea,
    SelectProfessionsDrawer,
    SelectTopicsDrawer,
    SelectPrimaryFieldsDrawer,
    SingleSelect
  },

  props: {
    disabled: Boolean,
    newLearningPlan: Boolean,
  },

  data () {
    return {
      visibilityOptions: [
        { label: 'Public', value: 'public' },
        { label: 'Private', value: 'private' },
        { label: 'Link Access', value: 'link' },
      ],
    }
  },

  beforeDestroy () {
    this.resetLearningPlan()
  },

  computed: {
    learningPlan: get('learningPlans/learningPlan'),
    professions: get('lists/professions/professions'),
    primaryFields: get('lists/primaryFields/primaryFields'),
    flattenedTopics: get('lists/topics/flattened'),

    shortDescriptionCharacters () {
      return this.learningPlan.short_description ? this.learningPlan.short_description.length : 0
    },

    facebookShareUrl () {
      return `http://www.facebook.com/sharer.php?u=${this.learningPlan.public_url}`
    },

    twitterShareUrl () {
      return `http://twitter.com/share?text=${this.learningPlan.name}&url=${this.learningPlan.public_url}`
    },

    linkedinShareUrl () {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.learningPlan.public_url}`
    },

    professionsFiltered () {
      return this.learningPlan.professions
    },

    topicsOrdered () {
      const parents = this.learningPlan.topics.filter(topic => !topic.parent_id)

      return sortBy(parents, 'name').reduce(
        (topics, topic) => [...topics, topic, ...this.learningPlan.topics.filter(child => child.parent_id === topic.id)],
        []
      )
    },

    primaryFieldsOrdered () {
      return this.learningPlan.primary_fields
    },

    visibilitySelected: {
      get () {
        return this.visibilityOptions.find(({ value }) => value === this.learningPlan.visibility)
      },

      set (value) {
        this.stateVisibility = value
      }
    },

    professionsSelected: {
      get () {
        return this.stateProfessions.map(({ id }) => id)
      },

      set (value) {
        this.stateProfessions = this.professions.filter(({ id }) => value.includes(id))
      }
    },

    primaryFieldsSelected: {
      get () {
        return this.statePrimaryFields.map(({ id }) => id)
      },

      set (value) {
        this.statePrimaryFields = this.primaryFields.filter(({ id }) => value.includes(id))
      }
    },
  },

  methods: {
    setUser: call('users/setUser'),
    deleteLearningPlan: call('learningPlans/deleteLearningPlan'),
    updateLearningPlan: call('learningPlans/updateLearningPlan'),
    resetLearningPlan: call('learningPlans/resetLearningPlan'),
    storeLearningPlan: call('users/learningPlans/storeLearningPlan'),
    storeUserActivity: call('users/storeUserActivity'),
    getProfessions: call('lists/professions/getProfessions'),

    async copyPublicUrl () {
      await copyToClipboard(this.learningPlan.public_url)

      toast('Link copied to clipboard.', 'success')
    },

    async logShareActivity (platform) {
      await this.storeUserActivity({ activity: 'share', subjectType: 'learning_plan', subjectId: this.learningPlan.id, properties: { platform } })
    },

    async save () {
      await handleServerConflict(async (headers) => {
        await this.updateLearningPlan({
          id: this.learningPlan.id,
          payload: {
            name: this.learningPlan.name,
            short_description: this.learningPlan.short_description,
            description: this.learningPlan.description,
            visibility: this.learningPlan.visibility,
            professions: this.learningPlan.professions.map(({ id }) => ({ id })),
            topics: this.learningPlan.topics.map(({ id }) => ({ id })),
            primary_fields: this.learningPlan.primary_fields.map(({ id }) => ({ id })),
          },
          headers
        })
      })

      toast('Learning plan was updated successfully.', 'success')
    },

    async store () {
      await this.setUser(this.$user)
      const learningPlan = await this.storeLearningPlan({
        name: this.learningPlan.name,
        short_description: this.learningPlan.short_description,
        description: this.learningPlan.description,
        visibility: this.learningPlan.visibility ?? 0,
        professions: this.learningPlan.professions.map(({ id }) => ({ id })),
        topics: this.learningPlan.topics.map(({ id }) => ({ id })),
        primary_fields: this.learningPlan.primary_fields.map(({ id }) => ({ id })),
      })

      this.$router.replace({ name: 'my.learning-plans.single', params: { learningPlanId: learningPlan.id } })
    },

    removeTopic (topic) {
      const topics = [...this.learningPlan.topics]

      // Get any children topics for the topic to be removed and remove them from the
      // array of selected topics first.
      const children = topics.filter(child => child.parent_id === topic.id)

      for (const child of children) {
        topics.splice(topics.findIndex(({ id }) => id === child.id), 1)
      }

      // Remove the selected topic.
      topics.splice(topics.findIndex(({ id }) => id === topic.id), 1)

      this.stateTopics = topics
    },

    removeProfession (profession) {
      const professions = [...this.professionsFiltered]
      const index = professions.findIndex(({ id: professionId }) => professionId === profession.id)

      if (index === -1) {
        return
      }

      professions.splice(index, 1)
      this.stateProfessions = professions

      const professionIds = professions.map(({ id }) => id)

      this.topicsSelected = this.flattenedTopics
        .filter(({ id }) => {
          return this.topicsSelected.includes(id)
        })
        .filter((topic) => {
          if (topic.profession_id) {
            return professionIds
              .includes(topic.profession_id)
          }

          return professionIds
            .includes(this.flattenedTopics.find(({ id }) => id === topic.parent_id).profession_id)
        })
        .map(({ id }) => id)

      this.primaryFieldsSelected = this.primaryFields
        .filter(({ profession_id: professionId }) => {
          return professionIds.includes(professionId)
        })
        .map(({ id }) => id)
    },

    removePrimaryField (id) {
      const primaryFields = [...this.learningPlan.primary_fields]
      const index = primaryFields.findIndex(({ id: primaryFieldId }) => primaryFieldId === id)

      if (index === -1) {
        return
      }

      primaryFields.splice(index, 1)
      this.statePrimaryFields = primaryFields
    },

    async confirmDeleteLearningPlan () {
      if (
        !await swal({
          title: 'Delete Learning Plan',
          text: 'Are you sure you want to delete this learning plan?',
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: { text: 'Yes, delete learning plan', value: true, visible: true, closeModal: true },
          },
          dangerMode: true,
          closeOnClickOutside: false
        })
      ) {
        return
      }

      await this.deleteLearningPlan(this.learningPlan.id)

      toast('Learning plan was deleted successfully.', 'success')

      this.$emit('deleted')
    }
  }
}
</script>
