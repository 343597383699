<template>
  <multiselect
    placeholder="Select"
    :options="subscriptionPlanOptions"
    label="label"
    track-by="value"
    v-model="subscriptionPlansSelected"
    v-bind="{ ...$attrs }"
    :show-labels="false"
    :allow-empty="true"
  >
    <template #tag>
      <span></span>
    </template>
  </multiselect>
</template>

<script>
import { call, get } from 'vuex-pathify'

export default {
  props: {
    value: [Array, Object, String],
  },

  computed: {
    plans: get('lists/subscriptionPlans/subscriptionPlans'),

    subscriptionPlanOptions () {
      const opts = [{
        label: 'All',
        value: null,
        role: null
      }]

      for (let i = 0; i < this.plans.length; i++) {
        if (this.plans[i].role === 'user' && opts.find(({ role }) => role === 'user')) {
          opts.find(({ role }) => role === 'user').value.push(this.plans[i].id)
          continue
        }

        opts.push({
          label: this.plans[i].name,
          value: [this.plans[i].id],
          role: this.plans[i].role
        })
      }

      return opts
    },

    subscriptionPlansSelected: {
      get () {
        if (!this.value) {
          return {
            label: 'All',
            value: null,
          }
        }
        return this.subscriptionPlanOptions.find(({ value }) => {
          return this.value && JSON.stringify(this.value) === JSON.stringify(value)
        })
      },

      set ({ value }) {
        this.$emit('input', value)
      }
    }
  },

  async created () {
    await this.getSubscriptionPlans()
  },

  methods: {
    getSubscriptionPlans: call('lists/subscriptionPlans/getSubscriptionPlans')
  }
}
</script>
