var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('app-header',{attrs:{"title":"Professional Development Plans"}}),_vm._t("nav"),_c('portal',{attrs:{"to":"headerRight"}},[_c('router-link',{attrs:{"custom":"","to":{
            name: 'my.professional-development-plans.add.overview',
          }}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" Plan")])])],1),_c('div',{staticClass:"container my-4"},[_c('my-professional-development-plans-list-filters',{staticClass:"mb-5",model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}),_c('c-data-list',{staticClass:"list-data no-hover",attrs:{"id":"development-plan-data-list","items":_vm.groupedProfessionalDevelopmentPlans},on:{"load":_vm.load},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('h2',[_vm._v(_vm._s(item['year']))]),_vm._l((item['plans']),function(plan){return _c('div',{key:plan.id,staticClass:"card"},[_c('router-link',{attrs:{"to":{
                name: 'my.professional-development-plans.single.overview',
                params: { professionalDevelopmentPlanId: plan.id }
              }}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center"},[_vm._v(_vm._s(plan.name))]),_c('div',{staticClass:"col-lg-3 d-flex align-items-center"},[_c('p',{staticClass:"text-muted small mb-0"},[_vm._v("Last updated: "+_vm._s(_vm.formatDate(plan.updated_at)))])])])])])],1)})]}}])})],1),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }