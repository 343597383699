<template>
  <fragment>
    <app-header title="Learner Profile" />

    <slot name="nav" />

    <users-single-profile />

    <slot name="footer" />
  </fragment>
</template>

<script>
import UsersSingleProfile from '@/views/users/UsersSingleProfile.vue'
export default {
  components: {
    UsersSingleProfile
  },
}
</script>
