<template>
  <fragment>
    <slot name="nav" />

    <div class='col'>
      <div class="card">
        <div class="card-body">
          <h2>Reflection</h2>
          <p>
            Complete this at the end of the year of when your CPD requirements have been met.          </p>
          <div class="row">
            <div class="mb-3">
                <label class="form-label">What goals have been achieved?</label>
                <c-input v-model="reflection.goals_achieved" />
            </div>
            <div class="mb-3">
                <label class="form-label">What goals have not been fully met?</label>
                <c-input v-model="reflection.goals_not_met" />
            </div>
            <div class="mb-3">
                <label class="form-label">Are there any new goals identified throughout the year?</label>
                <c-input v-model="reflection.new_goals" />
            </div>
            <div class="mb-3">
                <label class="form-label">How many hours have been spent developing and maintaining your PDP?</label>
                <p class="text-muted small">Time spent on developing and maintaining your PDP counts towards your RP CPD requirements.</p>
                <div class="input-group">
                  <span class="input-group-text">RP</span>
                  <c-input type="number" placeholder="0.00" class="form-control text-end no-increment-arrows" v-model="reflection.hours_spent" />
                  <span class="input-group-text">hrs</span>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label">What method did you use to find your CPD opportunities?</label>
                <p class="text-muted small">Eg: Med CPD, Google, LinkedIn, paper flyers, email, word of mouth.</p>
                <c-input v-model="reflection.method" />
            </div>
            <div class="text-end">
              <c-button class="btn-md" @click="save">
                Save
              </c-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </fragment>
</template>

<script>

import { toast } from '@chameleon/chameleon-vue'
import { call, get } from 'vuex-pathify'
import { stateMapper } from '@/vuex/modules/professional-development-plans'
import { http } from '@/utils'

export default {
  mixins: [stateMapper],

  data () {
    return {
      disabled: Boolean,
      params: null,
      reflection: {
        goals_achieved: '',
        goals_not_met: '',
        new_goals: '',
        hours_spent: '',
        method: ''
      }
    }
  },

  computed: {
    professionalDevelopmentPlan: get('professionalDevelopmentPlans/professionalDevelopmentPlan'),
    professionalDevelopmentPlanReflection: get('professionalDevelopmentPlanReflection/professionalDevelopmentPlanReflection'),
  },
  async created () {
    await this.getProfessionalDevelopmentPlan(this.$route.params.professionalDevelopmentPlanId)
    const response = await http.get(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/annual-reflection`)
    if (response.data) {
      this.reflection = response.data
    }
  },
  methods: {
    getProfessionalDevelopmentPlan: call('professionalDevelopmentPlans/getProfessionalDevelopmentPlan'),
    updateProfessionalDevelopmentPlanReflection: call('professionalDevelopmentPlans/updateProfessionalDevelopmentPlan'),

    async save () {
      const payload = {
        goals_achieved: this.reflection.goals_achieved,
        goals_not_met: this.reflection.goals_not_met,
        new_goals: this.reflection.new_goals,
        hours_spent: this.reflection.hours_spent,
        method: this.reflection.method,
        user_id: this.professionalDevelopmentPlan.user_id,
        id: this.reflection.id
      }
      if (this.reflection.id) {
        const response = await http.put(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/annual-reflection`, payload)
        toast('Professional development plan was updated successfully.', 'success')
        return response.data
      }
      const response = await http.post(`/professional-development-plans/${this.$route.params.professionalDevelopmentPlanId}/annual-reflection`, payload)
      toast('Professional development plan was updated successfully.', 'success')
      return response.data
    },
  }
}
</script>
