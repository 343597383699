<template>
  <fragment>
    <app-header title="Subscription" />

    <slot name="nav" />

    <div class="container container-max-md mt-4">
      <router-view />
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'

export default {
  created () {
    this.getPackages()
  },

  computed: {
    subscriptionPlan: get('packages/package'),
  },

  methods: {
    getPackages: call('packages/getPackages'),
  },
}
</script>
