<template>
  <validation-observer tag="form" class="card w-100" ref="validation" @submit.prevent>
    <div class="card-body card-body-lg d-flex flex-column">
      <h2 class="card-title">Change Password</h2>

      <validation-provider
        tag="div"
        class="mb-4"
        name="current password"
        rules="required"
      >
        <label>Current Password</label>
        <c-input
          v-model="currentPassword"
          type="password"
        />
        <c-help-block/>
      </validation-provider>

      <validation-provider
        tag="div"
        class="mb-4"
        ref="password"
        mode="aggressive"
        :bails="false"
        name="password"
        :rules="{
          required: true,
          min: { length: 8 },
          contains_special_character: true,
          contains_number: true,
          is: passwordConfirmation
        }"
      >
        <label>Password</label>
        <c-input
          type="password"
          v-model="password"
        />
        <c-help-block/>
      </validation-provider>

      <validation-provider tag="div" name="repeat password" class="mb-4" :rules="{ is: password, required: true }" mode="aggressive">
        <label>Repeat Password</label>
        <c-input
          type="password"
          v-model="passwordConfirmation"
        />
        <c-help-block/>
      </validation-provider>

      <password-strength-bar
        v-bind="{ password, label: 'Password Strength' }"
        class="mb-4"
      />

      <div class="text-end mt-auto">
        <c-button
          type="submit"
          variant="primary"
          @click="save"
          validate
        >
          Change Password
        </c-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { http } from '@/utils'
import { helperComputed, helperMethods } from '@/vuex/modules/users'
import { toast } from '@chameleon/chameleon-vue'
import PasswordStrengthBar from '@/components/PasswordStrengthBar'

export default {
  data () {
    return {
      currentPassword: null,
      password: '',
      passwordConfirmation: null
    }
  },

  components: {
    PasswordStrengthBar
  },

  computed: {
    ...helperComputed
  },

  methods: {
    ...helperMethods,

    async save () {
      await http.put(`/users/${this.user.id}/password`, {
        current_password: this.currentPassword,
        password: this.password,
        password_confirmation: this.passwordConfirmation
      })

      toast('Password updated successfully.', 'success')

      this.currentPassword = null
      this.password = ''
      this.passwordConfirmation = null

      this.$refs.validation.reset()
    }
  }

}
</script>
