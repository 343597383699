<template>
  <div class="card">
    <div class="card-body">
      <h2>Invoice {{ invoice.stripe_invoice_number }}</h2>

      <dl class="row">
        <div class="col-6 col-md-3">
          <dt>Due Date</dt>
          <dd>{{ dueDate }}</dd>
        </div>

        <div class="col-6 col-md-3">
          <dt>Amount (inc GST)</dt>
          <dd>{{ amount }}</dd>
        </div>

        <div class="col-6 col-md-3">
          <dt>Owing</dt>
          <dd>{{ amountOwing }}</dd>
        </div>

        <div class="col-6 col-md-3">
          <dt>Status</dt>
          <dd>
            <span class="status" :class="'status-' + (isPaid ? 'success' : 'danger')">
              {{ invoice.status }}
            </span>
          </dd>
        </div>
      </dl><!-- /.row -->

      <div v-if="!$isA('admin') && invoice.invoice_url && !isPaid" class="p-3 bg-gray-200 text-end mb-4">
        <a :href="invoice.invoice_url" class="btn btn-sm btn-warning" target="_blank">
          <i class="far fa-credit-card"></i> Manual Payment
        </a>
      </div>

      <a
        v-if="invoice.invoice_url"
        :href="invoice.invoice_url"
        class="btn btn-md text-primary"
        target="_blank"
      >
        View Invoice
      </a>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import dayjs from 'dayjs'

export default {
  async created () {
    await this.getInvoice(this.$route.params.invoiceId)
  },

  computed: {
    invoice: get('invoices/invoice'),

    isPaid () {
      return this.invoice.status === 'Paid'
    },

    dueDate () {
      return dayjs(this.invoice.due_date).format('DD/MM/YYYY')
    },

    amount () {
      return (this.invoice.amount / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },

    amountOwing () {
      return ((this.invoice.amount - this.invoice.amount_paid) / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
  },

  methods: {
    getInvoice: call('invoices/getInvoice'),
  },
}
</script>
