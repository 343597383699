<template>
  <fragment>
    <app-header title="My Account" />

    <slot name="nav" />

    <div class="container mt-4">
      <div class="row">
        <div class="col-xl-6 d-flex">
          <!-- change password card -->
          <change-password-card />
        </div>
        <div class="col-xl-6 d-flex">
          <!-- 2fa card -->
          <two-factor-card />
        </div>
      </div>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import ChangePasswordCard from '@/components/users/ChangePasswordCard'
import TwoFactorCard from '@/components/users/TwoFactorCard.vue'

export default {
  components: {
    ChangePasswordCard,
    TwoFactorCard
  }
}
</script>
