<template>
  <validation-observer tag="form" class="card" @submit.prevent>
    <div class="card-body card-body-lg">
      <slot name="header" />

      <div class="mb-3">
        <p class="form-label">First Name</p>
        <div class="row">
          <validation-provider
            tag="div"
            class="col-4 col-sm-3"
            name="title"
            rules="required"
          >
            <label class="visually-hidden">Title</label>

            <title-select v-model="stateTitle" />

            <c-help-block />
          </validation-provider>

          <validation-provider
            tag="div"
            class="col"
            name="first name"
            rules="required"
          >
            <label class="visually-hidden">First Name</label>

            <c-input type="text" v-model="stateFirstName" />

            <c-help-block />
          </validation-provider>
        </div>
      </div>

      <validation-provider
        tag="div"
        class="mb-3"
        name="last name"
      >
        <label class="form-label">Last Name</label>

        <c-input type="text" v-model="stateLastName" />

        <c-help-block />
      </validation-provider>

      <validation-provider
        tag="div"
        class="mb-4"
        name="email"
        rules="required|email"
      >
        <label class="form-label">Email</label>

        <c-input type="email" v-model="stateEmail" />

        <c-help-block />
        <p class="text-info mt-1" v-if="stateNewEmail">
          Email change pending for {{ stateNewEmail }}
        </p>
      </validation-provider>

      <validation-provider tag="div" class="mb-4" name="date of birth" rules="required" v-if="isA('user')">
        <label>Date of Birth</label>
        <c-date v-model="stateDob" />
        <c-help-block/>
      </validation-provider>

      <!-- status -->
      <validation-provider tag="div" class="mb-4" name="status" rules="required" v-if="isA('user')">
        <label class="form-label"> Account Status</label>
        <single-select v-model="stateStatus" :options="optionsStatus" :preselect="false"/>
        <c-help-block />
      </validation-provider>

      <validation-provider
        tag="div"
        class="mb-4"
        name="mobile"
        rules="required"
        v-if="isA('provider')"
        v-slot="{ failed }"
      >
        <label class="form-label">Mobile</label>

        <imask-input
          v-model="stateMobile"
          mask="0000 000 000"
          :unmask="true"
          :class="{ 'is-invalid': failed }"
          class="form-control"
        />

        <c-help-block />
      </validation-provider>

      <template v-if="!hideNotificationSettings && (isA('user') || isA('provider'))">
        <h3 class="mt-5">Notification Preferences</h3>

        <div class="form-check">
          <input
            type="checkbox"
            id="form-check-marketing"
            class="form-check-input"
            v-model="stateReceiveMarketing"
          />
          <label for="form-check-marketing" class="form-check-label">
            I would like to receive occasional marketing communication from Med
            CPD.
          </label>
        </div>

        <div class="row" v-if="isA('user')">
          <div class="col">
            <div class="form-check">
              <input
                type="checkbox"
                id="form-check-research"
                class="form-check-input"
                v-model="stateParticipateFeedback"
              />
              <label for="form-check-research" class="form-check-label">
                I would like to participate in the community feedback program.
              </label>
            </div>
          </div>
          <div class="col-auto d-flex align-items-center">
            <button
              type="button"
              data-bs-content="By participating in the community feedback program we will occasionally ask you to provide feedback on what training courses you are looking for and how providers could improve them."
              v-c-popover
              class="btn btn-question-popover"
            >
              <i class="far fa-question-circle"></i>
            </button>
          </div>
        </div>
      </template>

      <template v-if="isA('provider') || isA('user')">
        <hr class="my-4">
        <div class="mb-3">
          <div class="row">
            <div class="col">
              <span class="control-label">Status</span>
            </div>
            <div class="col-auto">
              <span class="status" :class="statusClass">{{ stateStatus }}</span>
            </div>
          </div>
        </div>
        <div class="mb-3" v-if="$isA('admin')">
          <label class="form-label">Linked Training Provider</label>
          <provider-select v-model="stateProvider"/>
        </div>
      </template>

      <slot />
    </div>
  </validation-observer>
</template>

<script>
import { stateMapper as userStateMapper } from '@/vuex/modules/users'
import { get } from 'vuex-pathify'
import TitleSelect from '@/components/users/TitleSelect.vue'
import { IMaskComponent } from 'vue-imask'
import SingleSelect from '@/components/forms/SingleSelect.vue'
import ProviderSelect from '@/components/providers/ProviderSelect.vue'

export default {
  components: {
    TitleSelect,
    'imask-input': IMaskComponent,
    SingleSelect,
    ProviderSelect,
  },

  props: {
    hideNotificationSettings: Boolean
  },

  data () {
    return {
      optionsStatus: [
        { label: 'Enabled', value: 'enabled' },
        { label: 'Pending', value: 'pending' },
        { label: 'Disabled', value: 'disabled' },
      ]
    }
  },

  mixins: [userStateMapper],

  computed: {
    isA: get('users/isA'),

    statusClass () {
      if (this.stateStatus === 'enabled') {
        return 'status-success'
      } else if (this.stateStatus === 'disabled') {
        return 'status-danger'
      } else {
        return 'status-info'
      }
    }
  },

}
</script>
