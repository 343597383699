export const copyToClipboard = async (text) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text)
    } else {
      const textarea = document.createElement('textarea')

      textarea.style.position = 'absolute'
      textarea.style.left = '-9999px'
      textarea.value = text

      document.body.appendChild(textarea)

      textarea.select()
      document.execCommand('copy')

      textarea.remove()
    }

    return Promise.resolve(true)
  } catch {
    return Promise.reject(new Error('failed to copy'))
  }
}
