<template>
   <fragment>
    <app-header :title="headerTitle" />

    <div class="container">
      <div class="row gx-5">
        <router-view>
          <template #nav>
            <div class="col-lg-3">
              <nav id="navbar-secondary-scroll" class="navbar navbar-expand-lg navbar-secondary">
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarInnerNav" aria-controls="navbarInnerNav" aria-expanded="false" aria-label="Toggle navigation"><i class="far fa-bars"></i> Navigation</button>
                  <div class="collapse navbar-collapse" id="navbarInnerNav">
                    <ul class="nav nav-page-secondary flex-column">
                      <li class="nav-item" :class="{ 'active': $route.name === 'my.professional-development-plans.add.overview' }">
                        <router-link class="nav-link" :to="{ name: 'my.professional-development-plans.add.overview' }">Overview</router-link>
                      </li>
                    </ul>
                </div>
              </nav>
            </div>
          </template>
        </router-view>
      </div>
    </div>

  </fragment>
</template>
<script>

export default {

  data () {
    return {
      ready: false,
      filters: {
        keywords: null
      }
    }
  },

  computed: {
    isOrganisationUser () {
      return this.$user.role.group === 'Organisation'
    },
    headerTitle () {
      const prefix = this.$route.path.includes('add') ? 'Add' : 'Edit'
      return prefix + ' Professional Development Plan'
    },
    tabs () {
      const tabs = [
        { title: 'Overview', id: 'user-activity', description: 'This report is designed to provide detailed information around the device usage for each user within each department in your organisation.' },
      ]
      return tabs
    }
  },
}
</script>
