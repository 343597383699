<template>
  <router-link tag="a" :to="{ name: 'users.single.details', params: { userId: user.id } }">
    <div class="row">
      <div class="col-12 col-xl-7">
          {{ user.title }} {{ user.name }}
      </div>
      <div class="col-12 col-lg-4 col-xl-3">
        <p class="text-truncate">
          <span class="data-label d-lg-none">Email Address:</span>
          {{ user.email }}
        </p>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 col-xl-2">
          <p>
            <span class="data-label d-lg-none">Account Status:</span>
            <span class="status status-success" v-if="user.status === 'enabled'">Enabled</span>
            <span class="status status-danger" v-if="user.status === 'disabled'">Disabled</span>
            <span class="status status-info" v-if="user.status === 'pending'">Pending</span>
          </p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  }
}
</script>
