<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title mb-0">Permissions</h2>
      </div>

      <ul class="list-data no-links no-hover">
        <li
          v-for="(abilities, group) in abilitiesGrouped"
          :key="group"
        >
          <div class="row">
            <div class="col-sm-4 col-md-3 col-xl-2 align-items-start">
              <h4 class="mb-0">{{ group | capitalise }}</h4>
            </div>

            <div class="col-sm-8 col-md-9 col-xl-10">
              <div class="flex-grow-1">
                <div
                  v-for="ability in abilities"
                  class="form-group mb-1 row"
                  :class="{ 'border-top pt-1': ability.spacer === true }"
                  :key="ability.id"
                >
                  <div class="col">
                    <ability-checkbox
                      v-model="abilitiesSelected"
                      v-bind="{ ability, role: user.role}"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="card-body text-end">
        <c-button
          type="button"
          @click="save"
          variant="primary"
        >
          Save Changes
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/utils'
import { call } from 'vuex-pathify'
import { helperComputed, helperMethods } from '@/vuex/modules/users'
import { useGroupsAbilities } from '@/mixins/abilities/use-groups-abilities'
import AbilityCheckbox from '@/components/users/AbilityCheckbox'
import { toast } from '@chameleon/chameleon-vue'

export default {
  mixins: [useGroupsAbilities()],

  components: {
    AbilityCheckbox
  },

  data () {
    return {
      abilitiesSelected: [],
      abilities: [
        { name: 'read courses', title: 'READ', group: 'courses' },
        { name: 'write courses', title: 'WRITE', group: 'courses' },
        { name: 'read providers', title: 'READ', group: 'organisation details' },
        { name: 'write providers', title: 'WRITE', group: 'organisation details' },
        { name: 'read users', title: 'READ', group: 'users' },
        { name: 'write users', title: 'WRITE', group: 'users' },
      ]
    }
  },

  async created () {
    if (this.user.provider.reports_access) {
      this.abilities.push({ name: 'read reports', title: 'READ', group: 'reports' })
    }
    this.abilitiesSelected = [...this.user.abilities]
  },

  computed: {
    ...helperComputed,
  },

  methods: {
    ...helperMethods,

    getAbilities: call('abilities/getAbilities'),

    async save () {
      const staticAbilities = this.abilities.map(({ name }) => name)
      await http.put(`users/${this.user.id}/permissions/provider`, {
        abilities: this.abilitiesSelected.filter(function ({ name }) {
          return staticAbilities.includes(name)
        }).map(({ name }) => name)
      }).then((res) => {
        toast('Saved successfully', 'success')
      }).catch((err) => {
        toast(err.response.data.message, 'danger')
      })
      await this.getUser(this.user.id)
    }
  }
}
</script>
