<template>
  <fragment>
    <slot name="nav" />

    <div class='col'>
      <validation-observer tag="form" class="d-flex w-100">
      <div class="card">
        <div class="card-body">
          <h2>Overview</h2>
          <p>
            This is a guide provided to you to assist with understanding and setting out your Professional Development Plan (PDP) for the year. Using this structure will simplify the process of achieving your CPD requirements and help to improve the beneficial impact on both your personal and professional development.
          </p>
          <p>
            Start by adding a PDP name and year.
          </p>
          <div class="row">
            <div class="col-12 col-md-9 mb-3">
              <validation-provider
                tag="div"
                name="name"
                class="mb-3"
                rules="required"
                vid="name"
              >

                <label class="form-label">PDP Name</label>

                <c-input ref="name" name="name" v-model="name"/>

                <c-help-block />
              </validation-provider>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <validation-provider
                tag="div"
                name="year"
                vid="year"
                class="mb-3"
                rules="required"
              >

                <label class="form-label">Year</label>

                <multiselect
                  :options="dateOptions"
                  :preselect-first="true"
                  :show-labels="false"
                  :allow-empty="false"
                  v-model="year"
                  value="value"
                >

                </multiselect>

                <c-help-block />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-4">
        <div class="col-auto">
          <c-button class="btn-md" @click="save">
            Save
          </c-button>
        </div>
      </div>
        </div>

      </div>
    </validation-observer>
    </div>

  </fragment>
</template>

<script>
import { toast } from '@chameleon/chameleon-vue'
import { http } from '@/utils'

export default {

  data () {
    return {
      disabled: false,
      params: null,
      name: '',
      year: '',
    }
  },
  computed: {
    dateOptions () {
      const today = new Date().getFullYear()
      const options = []

      for (let i = 2020; i <= today; i++) {
        options.push(`${i}`)
      }
      return options
    },
  },
  methods: {
    async createProfessionalDevelopmentPlan () {
      return await http.post('/professional-development-plans', { name: this.name, year: this.year })
    },
    async save () {
      const response = await this.createProfessionalDevelopmentPlan()
      this.$router.push(`/my/professional-development-plans/${response.data.data.id}/overview`)
      toast('Professional development plan was created successfully.', 'success')
    }
  }
}
</script>
